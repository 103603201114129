import React,{ useState,useEffect} from 'react';
//import AuthContext from '../../context/AuthContext';
import { getDatosUsers } from '../../api/Login';
import {
  Navigate,useNavigate
} from "react-router-dom";

function Home() {
    const [auth,setAuth] = useState(null);
    
    let navigate = useNavigate();
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
     // console.log(user);
      if(user === null){
        navigate("/login", { replace: true });
      }else{
        setAuth(user);
      }
    }
    init();
  }, [])
  return(
    <>
      {
      auth?(
        auth.rol == 1?(
          <Navigate to="/users" replace={true} />
        ): auth.rol == 2  ? (
          <Navigate to="/rutas" />
        ): auth.rol == 3 ?(
          <Navigate to="/rutas" />
        ):(
            auth.rol == 0 ? (
              <Navigate to="/contratos" replace={true} />
            ):(
              <Navigate to="/login" />    
            )
            
        )
      ):(
        <p></p>
      )
      }
    </>
  )
}

export default Home;

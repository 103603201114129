import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import "../articulos/Articulos.css";
import { useNavigate } from "react-router-dom";
import {
    Link,
    NavLink,
    Navigate
  } from "react-router-dom";


import { getTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';

import { activarDesactvarArticuloApi, getAllArticulosApi, getAllCategoriasArticulosApi } from '../../api/Articulos';
import { addProductCarApi } from '../../api/Carrito';
import Carrito from '../carrito/Carrito';

function Articulos() {
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    const [busqueda,setBusqueda] = useState("");
    const [alert, setAlert] = useState(false);
    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0);
    /**cosas para los modals principales */
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    
    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");
    const [msjError,setMsjError] = useState("");
    let navigate = useNavigate();
    const cerrarModal = () => setShowModal(false);
    const abrirModal = () => setShowModal(true);

    /**variables componente */
    
    const [idCategoria,setIdCategoria] = useState("0");
    const [idArticulo,setIdArticulo] = useState(0);
    const [articulosMueble,setArticulosMueble] = useState(null);
    const [articulosCategorias,setArticulosCategorias] = useState(null);
    const [activoArticulo,setActivoArticulo] = useState(null);
    
    /**variables para el carrito */
    const [modal,setModalAbrir] = useState(false);
    const [idProductoCar,setIdProductCar] = useState('0');
    const [opcionCompra,setOpcionCompra] = useState('1');
    const [nombreProducto,setNombreProducto] = useState('Generico');

    const setModal = () => setModalAbrir(false);
      /*Verificar si no ahy un usuario logueado*/
    useEffect(() => {
        const init = async() =>{
        const user = await getDatosUsers();
        if(user === null){
            navigate("/login", { replace: true });
        }else{
            if (user.rol!=1 && user.rol!=2 && user.rol!=3) {
                navigate("/", { replace: true });
            } else {
            /**obtener roles */
            setIsLoading(true);
            //getRutas('',1);
            /**obtener las categoraias */
               /**obtener roles */
            (async()=>{
                const TOKEN = await getTokenApi();
                const formdata = {
                    "token" : TOKEN,
                }
                const catArticulos = await getAllCategoriasArticulosApi(formdata);
                setArticulosCategorias(catArticulos.categorias);
            })();

            getAllArticulos("",1,"0");
            setAuth(user);
            }
        }
        }
        init();
    }, [getAllArticulos]);
    const getAllArticulos = useCallback( async(buscar,page_number,idCat) => {
        setBusqueda(buscar);
        setIdCategoria(idCat);
        const TOKEN = await getTokenApi();
        const formdata = {
            "busqueda": buscar,
            "token" : TOKEN,
            "pagina" : page_number,
            "categoria" : idCat,
          }
        setCurrPage(page_number);
        var articulosAll = await getAllArticulosApi(formdata);
        //console.log(usuario.totalPaginas);
        setTotalPaginas(articulosAll.totalPaginas); 
        setArticulosMueble(articulosAll.articulos); 
        setIsLoading(false); 
      },[]);
    /**modal para crear y editar */
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    var setBusquedaFuncion = (valor) =>{
        
        getAllArticulos(valor,1,idCategoria);
        //getRutas(valor,1);
    }
    function handlePageChange(page) {
        setIsLoading(true);
        setCurrPage(page);
        //console.log("controler normal",page);
        //getRutas(busqueda,page);
        // ... do something with `page`
  }
  function setIdCategoriaEstado(idCat){
    setIdCategoria(idCat);
    setIsLoading(true);
    getAllArticulos(busqueda,1,idCat);
  }
  var activarDesactivarArticulo = (articuloActivar) =>{
    setIdArticulo(articuloActivar.Id_Mueble);
    setAlert(false);
    if (articuloActivar.Estatus_Mueble == 1) {
      setActivoArticulo(0);
      setTitulomodal("Desactivar");
      setBotonModal("Desactivar");
      setMensajeModal("¿Esta seguro que desea desactivar el articulo "+articuloActivar.Nom_Mueble+" ?");
    }else{
      setActivoArticulo(1);
      setTitulomodal("Activar");
      setBotonModal("Activar");
      setMensajeModal("¿Esta seguro que desea activar el articulo "+articuloActivar.Nom_Mueble+" ?");
    }
    abrirModal();
   }
   var activarDesactivarMueble  = async() =>{
    setIsLoading(true);
    const TOKEN = await getTokenApi();
    const formdata = {
        "idMueble":idArticulo,
        "token" : TOKEN,
        "Estatus_Mueble" : activoArticulo,
      }
    // console.log('activarDesactivarUsuario',formdata);
     const response = await activarDesactvarArticuloApi(formdata);
      if(response.succes === "succes"){
        toast.success(response.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
            });
            setShowModal(false);
            getAllArticulos(busqueda,currPage,idCategoria);
    }else{
      setAlert(true);
      setMsjError(response.message);
    }
    setIsLoading(false);
  }
  const addProductCartModal = (id) => {
    setIdProductCar(id);
    console.log('abrirModal');
    
    setModalAbrir(true);
  }
  const addProductCart = async () =>{
    console.log(idProductoCar);
    console.log(opcionCompra);
    setIsLoading(true);
    const response = await addProductCarApi(idProductoCar,1,opcionCompra);
    if(response){
        toast.success('"Articulo agregado al carrito"', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
            });
    }else{
        toast.error('"Error al agregar el Articulo al carrito"', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
            });

    }
    setModalAbrir(false);
    setIsLoading(false);
  }
    return (
        <>
            <div className="contenido">
                <div className="sub_contenido">
                    <div className="filtros_pag">
                        <Form.Group>
                            <InputGroup>
                                <Form.Label>Categorías</Form.Label>&nbsp;
                                <Form.Control onChange={event => setIdCategoriaEstado(event.target.value)} as="select">
                                    <option value="0">Todas las Categorías</option>
                                    {
                                        articulosCategorias != null && 
                                        (
                                            map(articulosCategorias,(articuloCategoria,index)=>(
                                                <option value={`${articuloCategoria.Id_Categoria}`}>{articuloCategoria.Nom_Categoria}</option>
                                            ))
                                        )
                                    }
                                </Form.Control>&nbsp;
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <InputGroup>
                                <Form.Control placeholder="Busqueda"   
                                    id="busquedaInput"
                                    name="busquedaInput"
                                    type="text"
                                    onChange={event => setBusquedaFuncion(event.target.value)}
            
                                />
                                &nbsp;
                                {
                                    auth != null &&(
                                        auth.rol == 1 && (
                                            <a href={`/articulo`} title="Articulo Nuevo" className=" btn btn-primary botonIcono botonNuevo showmessages">Nuevo Artículo</a>
                                        )
                                    )
                                    
                                }
                            </InputGroup> 
                        </Form.Group>  
                    </div>
                    {
                    isLoading ? (
                        <LoadingSpinner
                        showSpinner={isLoading}
                        />
                        ):
                    (
                    <>
                    </>
                    )
                    }
                    
                    {/**articulos o productos */}
                    <div className='contenedor-containerlistProducts'>
                        {
                            articulosMueble != null && 
                            (
                                map(
                                    articulosMueble,(articulo,index)=>(
                                        <div key={articulo.Id_Mueble}> 
                                            <div className="contenedor-containerProductlistProducts">                      
                                                <div className="contenedor-productlistProducts">
                                                    <a href={`/verProducto/${articulo.Id_Mueble}`} >
                                                        <img
                                                            src={`${articulo.imagenMueble}`}
                                                            className="contenedor-imagelistProducts"
                                                            alt={articulo.Nom_Mueble}    
                                                        /> 
                                                        
                                                        <p title={articulo.Nom_Mueble} className="contenedor-titlelistProducts">{articulo.Nom_Mueble}</p>
                                                        <p>Precios</p>
                                                        {
                                                            Number.isInteger(articulo.Precio_Mueble) ? (
                                                            <>
                                                                <p className="contenedor-preciosProducts">
                                                                    De Contado ${articulo.PrecioContado_Mueble}&#46;00
                                                                </p>
                                                                <p className="contenedor-preciosProducts">
                                                                    a 3 meses ${articulo.Precio3Meses_Mueble}&#46;00
                                                                </p>
                                                                <p className="contenedor-preciosProducts">
                                                                    a 6 meses ${articulo.Precio6Meses_Mueble}&#46;00
                                                                </p>
                                                                <p className="contenedor-preciosProducts">
                                                                    Normal ${articulo.Precio_Mueble}&#46;00
                                                                </p>
                                                            </>
                                                            ):(
                                                            <>
                                                                <p className="contenedor-preciosProducts">
                                                                    De Contado ${articulo.PrecioContado_Mueble}&#46;00
                                                                </p>
                                                                <p className="contenedor-preciosProducts">
                                                                    a 3 meses ${articulo.Precio3Meses_Mueble}&#46;00
                                                                </p>
                                                                <p className="contenedor-preciosProducts">
                                                                    a 6 meses ${articulo.Precio6Meses_Mueble}&#46;00
                                                                </p>
                                                                <p className="contenedor-preciosProducts">
                                                                    Normal ${articulo.Precio_Mueble}&#46;00
                                                                </p>
                                                            </>
                                                            )
                                                        }
                                                        
                                                    </a>
                                                    <div className="configuracionBotones">
                                                        {
                                                            auth.rol == 1 && 
                                                            ( 
                                                            <>
                                                                <Button onClick={() => addProductCartModal(articulo.Id_Mueble)} title="Agregar al carrito" className='botonCompras transparente'></Button>
                                                                <a title="Editar" href={`/articuloEditar/${articulo.Id_Mueble}`} className='botonEditar transparente'></a>
                                                                <Button onClick={() => activarDesactivarArticulo(articulo)} title={ articulo.Estatus_Mueble != 1 ? ('Activar'):('Desactivar') } className={ articulo.Estatus_Mueble != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>
                                                            </>
                                                            )
                                                        }
                                                        {
                                                            auth.rol == 2 &&(
                                                                <Button onClick={() => addProductCartModal(articulo.Id_Mueble)} title="Agregar al carrito" className='botonCompras transparente'></Button>

                                                            )
                                                        }
                                                    </div>
                                                      
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            )
                        }
                    </div>
                    &nbsp;						
                    <div className="alignDerecha">
                        {
                        articulosMueble != null ?
                        ( 
                            <Pagination
                                total={totalPaginas}
                                current={currPage}
                                onPageChange={page => handlePageChange(page)}
                            />
                        ):(<p>No hay registros</p>)
                        }
                    </div>
                    <ToastContainer
                        transition={Flip}
                        position="bottom-center"
                        limit={1}
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        {/**modal activar desactivar usuario */}
        <Modal  show={showModal} onHide={cerrarModal} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title id="tituloModal">{tituloModal} Articulo</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
            <>
                {
                (alert == true) ?
                    <Container>
                    <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                        <Alert.Heading>Advertencia</Alert.Heading>
                        <p>
                        {msjError}
                        </p>
                    </Alert>
                </Container>
                :(<></>)
                }
            </>
            <Form.Group>
                <Form.Control placeholder="nombre"   
                id="idUsuarioActivar"
                name="idArticulo"
                type="hidden"
                defaultValue={idArticulo}
                />
            </Form.Group>
            <h4>{mensajemodal}</h4>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={activarDesactivarMueble} id="botonModal">
                {botonModal}
            </Button>
            <Button variant="secondary" onClick={cerrarModal}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
        {/**modal agregar al carrito */}
        <Modal  show={modal} onHide={setModal} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title id="tituloModal">Agregar al carrito</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
            <Container>
                <Form.Group>
                    <Form.Label>Forma de Pago</Form.Label>
                    <Form.Control onChange={event => setOpcionCompra(event.target.value)} as="select">
                        <option value="1">Compra Normal</option>
                        <option value="2">A 3 Meses</option>
                        <option value="3">A 6 Meses</option>
                        <option value="4">De Contado</option>
                    </Form.Control>
                </Form.Group>
            </Container>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={addProductCart} id="botonModal">
                Agregar
            </Button>
            <Button variant="secondary" onClick={setModal}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
      {/**fin modals */}
        </>
    )
}

export default Articulos;
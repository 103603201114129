import React from 'react';

function Footer() {
    return (
        <div className="foot">
          <div className="texto-foot" > MUEBLES SAN NICOLAS </div>
          <div className="version">
            Versión 1.0
          </div>
        </div>
    );
}

export default Footer;

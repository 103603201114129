import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import {
    useNavigate,useParams
  } from "react-router-dom";
  
import { getTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';
import Categoria from '../categoria/Categoria';
import { getCategoriasApi } from '../../api/Categorias';
import { findByCatIdApi, saveMuebleApi } from '../../api/Articulos';
import { API_URL } from '../../utils/Constant';
import Gallery from './Gallery';
import { addProductCarApi } from '../../api/Carrito';

function VerArticulo() {
    let params = useParams();
    let navigate = useNavigate();
    let timer = 3000;
    let delay = 3000;
    let prevent = false;
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    const [alert, setAlert] = useState(false);
    const [msjError,setMsjError] = useState("");
    const [articulo,setArticulo] = useState(null);
    const [categorias,setCategorias] = useState(null);

    /**variables para el carrito */
    const [modal,setModalAbrir] = useState(false);
    const [idProductoCar,setIdProductCar] = useState('0');
    const [opcionCompra,setOpcionCompra] = useState('1');
    const [nombreProducto,setNombreProducto] = useState('Generico');

    const setModal = () => setModalAbrir(false);

    const addProductCartModal = (id) => {
        setIdProductCar(id);
        console.log('abrirModal');
        
        setModalAbrir(true);
      }
    
    const addProductCart = async () =>{
        setIsLoading(true);
        const response = await addProductCarApi(idProductoCar,1,opcionCompra);
        if(response){
            toast.success('"Articulo agregado al carrito"', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
        }else{
            toast.error('"Error al agregar el Articulo al carrito"', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
    
        }
        setIsLoading(false);
    }
    useEffect(() => {
        const init = async() =>{
            const user = await getDatosUsers();
            if(user === null){
                navigate("/login", { replace: true });
            }else{
                if (user.rol!=1 && user.rol!=2 && user.rol!=3) {
                    navigate("/", { replace: true });
                } else {
                    setAuth(user);
                    (async()=>{
                        const TOKEN = await getTokenApi();
                        const formdata = {
                            "token" : TOKEN,
                            "idMueble" : params.articuloId,
                        }
                        const articuloApi = await findByCatIdApi(formdata);
                        const dataArticulo = JSON.parse(articuloApi.articulo);
                        setCategorias(JSON.parse(articuloApi.optionCat));
                        setArticulo(dataArticulo);
                    })();
                }
            }
        }
        init();
    }, []);
    return(
        <>
        
        {
            articulo != null ? (
                <div className="container-fluid">
                    <div className="row row row-cols-1 row-cols-sm-1 row-cols-md-2  row-cols-lg-2 justify-content-center">
                        <div className="col">
                            <div className="container-image">
                                {articulo.Imagenes_Mueble ? (
                                    <div className="image-producto">
                                        <Gallery  
                                            imagenes={articulo.Imagenes_Mueble} 
                                            imagen={articulo.imagenMueble}
                                        />
                                    </div>
                                ) :(
                                    <div>
                                        Cargando imagen
                                    </div>
                                )
                                }
                            </div>
                        </div>
                        <div className="col ">
                            <div className="container-data">
                                
                                <div className="container-text">
                                    <div className="container-nombre">
                                        <h3>{articulo.Nom_Mueble}</h3>
                                        {
                                            auth.rol == 1 && (
                                                <a title="Editar" href={`/articuloEditar/${articulo.Id_Mueble}`} className='botonEditar transparente'></a>
                                            )
                                        }

                                    </div>
                                    <div>
                                        <p className="title-desc">Categorias</p>
                                        {map(categorias,(detalle,index) => (
                                                <div key={index}>
                                                    <p className="det punto">&#183;</p>
                                                    <p className="det"> {detalle.label}</p>
                                                 </div>
                                            ))
                                        }
                                    </div>
                                    <div className="container-precio"><h3>Precios</h3></div>
                                    <div className="container-precio">
                                        <p className="text-precio">De contado $</p>
                                        <p className="text-precio precio">{articulo.PrecioContado_Mueble}</p>
                                    </div>
                                    <div className="container-precio">
                                        <p className="text-precio">A 3 Meses $</p>
                                        <p className="text-precio precio">{articulo.Precio3Meses_Mueble}</p>
                                    </div>
                                    <div className="container-precio">
                                        <p className="text-precio">A 6 Meses $</p>
                                        <p className="text-precio precio">{articulo.Precio6Meses_Mueble}</p>
                                    </div>
                                    <div className="container-precio">
                                        <p className="text-precio">Normal $</p>
                                        <p className="text-precio precio">{articulo.Precio_Mueble}</p>
                                    </div>
                                    <div className="container-disp">
                                        <p className="text-disp">Disponibilidad </p>
                                        <p className="text-disp">{articulo.Existencia_Mueble} </p>
                                    </div>
                                </div>
                                {
                                    auth.rol == 2 &&  auth.rol == 1 ? (
                                        <button type="button" onClick={() => addProductCartModal(articulo.Id_Mueble)}  className="btn btn-primary boton-compra">
                                            Agregar al carrito
                                        </button>
                                       
                                    ):(
                                        <>
                                        </>
                                    )
                                }
                               
                                <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            />
                                <div className="container-desc">
                                        <p className="text-disp">Descripción </p>
                                        <p className="text-disp">{articulo.Desc_Mueble} </p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            ):(
                <div>
                    <p>Cargando datos actualizando</p>
                </div>
            )
        }
         {/**modal agregar al carrito */}
        <Modal  show={modal} onHide={setModal} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title id="tituloModal">Agregar al carrito</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
            <Container>
                <Form.Group>
                    <Form.Label>Forma de Pago</Form.Label>
                    <Form.Control onChange={event => setOpcionCompra(event.target.value)} as="select">
                        <option value="1">Compra Normal</option>
                        <option value="2">A 3 Meses</option>
                        <option value="3">A 6 Meses</option>
                        <option value="4">De Contado</option>
                    </Form.Control>
                </Form.Group>
            </Container>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={addProductCart} id="botonModal">
                Agregar
            </Button>
            <Button variant="secondary" onClick={setModal}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
      {/**fin modals */}
        </>
    )
}
export default VerArticulo;
import { API_URL } from "../utils/Constant";
import { getTokenApi } from "./Token";

export async function getRutasCompraApi(formdata){
    try {
        const url= `${API_URL}Rutas/getAllRutas`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        return null;
    }
}
export async function saveRutasCompraApi(formdata){
    try {
        const url= `${API_URL}Rutas/saveRuta`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        return null;
    }
}
export async function activarRutasCompraApi(formdata){
    try {
        const url= `${API_URL}Rutas/activarRuta`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        return null;
    }
}


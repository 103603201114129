import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import {
    useNavigate,useParams
  } from "react-router-dom";
  
import { getTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';
import Categoria from '../categoria/Categoria';
import { getCategoriasApi } from '../../api/Categorias';
import { findByCatIdApi, saveMuebleApi } from '../../api/Articulos';
import { API_URL } from '../../utils/Constant';

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      
      color: state.isSelected ? '#black' : 'black',
      backgroundColor: state.isSelected ? '#5243aa' : 'white',
      backgroundColor: state.isFocused ? '#e8f0fe' : 'white',

    }),
    control: (provided) => ({
      ...provided,
     // marginTop: "5%",
    })
  }
function Articulo() {
    let params = useParams();
    let navigate = useNavigate();
    let timer = 3000;
    let delay = 3000;
    let prevent = false;

    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    const [alert, setAlert] = useState(false);
    const [msjError,setMsjError] = useState("");

    const [articulo,setArticulo] = useState(null);
    const [idArticulo,setIdArticulo] = useState(0);

    /**variables del propio componente*/
    const [archivos,setArchivos] = useState(null);
    const [archivo,setArchivo] = useState(null);
    const [categorias,setCategorias] = useState(null);
    const [categoriasOptions,setCategoriasOptions] = useState(null);
    const [codigo,setcodigo] = useState("");
    const [nombre,setNombre] = useState("");
    const [exisArticulo,setExisArticulo] = useState("");
    const [contadoArticulo,setContadoArticulo] = useState("");
    const [meses3Articulo,setMeses3Articulo] = useState("");
    const [meses6Articulo,setMeses6Articulo] = useState("");
    const [precioArticulo,setPrecioArticulo] = useState("");
    const [descArticulo,setDescArticulo] = useState("");
    const [nomBoton,setNomBoton] = useState("Registrar Articulo")
    const subirArchivos = (e)  =>{
        // console.log('onch');
         setArchivos(e);
     } 
     const subirArchivo = (e)  =>{
        // console.log('onch');
         setArchivo(e);
     } 
     var multiplesCategorias = (e) =>{
        //setDiasValor(Array.isArray(e)?e.map(x=>x.value):[]);
        console.log(e);
        
        setCategorias(e);
    }
    const calcularPrecios = (value) =>{
        setPrecioArticulo(value);
        setMeses3Articulo(parseFloat(value) * .70);
        setMeses6Articulo(parseFloat(value) * .80);
    }
    var updateArticulo = async() => {
        /**validaciones */
        setIsLoading(true);
        var errores = "";
        var error = 0;
        var uploadArchivos = 0;
        var imagenesCargadas = 0;
        if(codigo.toString() == ""){
            errores+="Debes ingresar el código del articulo\n";
            error=1;
        }

        if(descArticulo.toString() == ""){
            errores+="Debes ingresar la descripción del articulo\n";
            error=1;
        }
        if(precioArticulo.toString() == ""){
            errores+="Debes ingresar el precio del articulo\n";
            error=1;
        }
        if(precioArticulo.toString() == ""){
            errores+="Debes ingresar el precio del articulo\n";
            error=1;
        }
        if(meses3Articulo.toString() == ""){
            errores+="Debes ingresar el precio a 3 meses del articulo\n";
            error=1;
        }
        if(meses6Articulo.toString() == ""){
            errores+="Debes ingresar el precio a 6 meses del articulo\n";
            error=1;
        }
        if(meses6Articulo.toString() == ""){
            errores+="Debes ingresar el precio de contado del articulo\n";
            error=1;
        }
        if(meses6Articulo.toString() == ""){
            errores+="Debes ingresar la existencia del articulo\n";
            error=1;
        }
        /**validar las categorias */
        if(Array.isArray(categorias) && categorias.length== 0){
            errores+="Debes de seleccionar al menos una categoría\n";
            error=1;
        }else{
            if(categorias == null){
                errores+="Debes de seleccionar al menos una categoría\n";
                error=1;
            }
        }
        if(nombre.toString() == ""){
            errores+="Debes ingresar el nombre del articulo\n";
            error=1;
        }
        /**validar archivos cuando no esta editando */
        if(articulo == null){
            if (Array.isArray(archivo) == null && archivo.length == 0) {
                errores+="Debes de subir una imagen principal\n";
                error=1;
            }else{
                if(archivo == null){
                    errores+="Debes de subir una imagen principal\n";
                    error=1;
                }
            }
            if (Array.isArray(archivos) == null && archivos.length == 0) {
                errores+="Debes de subir imagenes que describan el articulo\n";
                error=1;
            }else{
                if(archivos == null){
                    errores+="Debes de subir imagenes que describan el articulo\n";
                    error=1;
                }
            }
        }else{
            /**validar para ver si se suben los archivos o no cuando esta editando*/
            if (Array.isArray(archivo) == null && archivo.length == 0) {
                errores+="Debes de subir una imagen principal\n";
                uploadArchivos=1;
            }else{
                if(archivo == null){
                    errores+="Debes de subir una imagen principal\n";
                    uploadArchivos=1;
                }
            }
            if (Array.isArray(archivos) == null && archivos.length == 0) {
                errores+="Debes de subir imagenes que describan el articulo\n";
                uploadArchivos=1;
            }else{
                if(archivos == null){
                    errores+="Debes de subir imagenes que describan el articulo\n";
                    uploadArchivos=1;
                }
            }
        }
        if(error != 1){
            const TOKEN = await getTokenApi();
            const f = new FormData();
            if (articulo == null) {
                for (let index = 0; index < archivo.length; index++) {
                    f.append("imagenPrincipal",archivo[index]);
                }  
                for (let index = 0; index < archivos.length; index++) {
                    f.append("imagenes[]",archivos[index]);
                } 
            }else{
                if (uploadArchivos != 1) {
                    imagenesCargadas = 1;
                    for (let index = 0; index < archivo.length; index++) {
                        f.append("imagenPrincipal",archivo[index]);
                    }  
                    for (let index = 0; index < archivos.length; index++) {
                        f.append("imagenes[]",archivos[index]);
                    } 
                }
            }
              
                  
            
            const producto = {
                "Codigo_Mueble": codigo,
                "Nom_Mueble": nombre,
                "Desc_Mueble": descArticulo,
                "Precio_Mueble":precioArticulo,
                "Precio3Meses_Mueble": meses3Articulo,
                "Precio6Meses_Mueble": meses6Articulo,
                "PrecioContado_Mueble": contadoArticulo,
                "Existencia_Mueble": exisArticulo,
                "Categorias_Mueble": categorias,
                "IdMueble": idArticulo,
                "imagenesCargadas":imagenesCargadas,
                "opcion" : (idArticulo == 0) ? 1:2 ,
                "token" :TOKEN,
            };
            f.append("producto",JSON.stringify(producto));
            const response = await saveMuebleApi(f);
            if(response.succes == "succes"){
                toast.success(response.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark"
                  });
                  let me = this;
                    timer = setTimeout(function() {
                    if (!prevent) {
                        //confirmarCompra();
                    }
                    prevent = false;
                    }, delay);
                    window.location.href = "/articulos";
                  //redirigir a articulos
                  //getSucursales(busqueda,1);
                  //navigate("/articulos", { replace: true });
              }else{
                setAlert(true);
                setMsjError(response.message);
                
                //setIsLoading(false);
              }
        }else{
            setAlert(true);
            setMsjError(errores);
        }
        setIsLoading(false);
    }
    
    useEffect(() => {
        const init = async() =>{
        const user = await getDatosUsers();
        if(user === null){
            navigate("/login", { replace: true });
        }else{
            if (user.rol!=1 ) {
                navigate("/", { replace: true });
            } else {
             
                if(params.articuloId != null) {
                    /**va a editar el articulo */
                    setIdArticulo(params.articuloId);
                    /**obtener los datos del articulo */
                    (async()=>{
                        const TOKEN = await getTokenApi();
                        const formdata = {
                            "token" : TOKEN,
                            "idMueble" : params.articuloId,
                        }
                        const articuloApi = await findByCatIdApi(formdata);
                        const dataArticulo = JSON.parse(articuloApi.articulo)
                        setNombre(dataArticulo.Nom_Mueble);
                        setcodigo(dataArticulo.Codigo_Mueble)
                        setExisArticulo(dataArticulo.Existencia_Mueble);
                        setContadoArticulo(dataArticulo.PrecioContado_Mueble);
                        setMeses3Articulo(dataArticulo.Precio3Meses_Mueble);
                        setMeses6Articulo(dataArticulo.Precio6Meses_Mueble);
                        setPrecioArticulo(dataArticulo.Precio_Mueble);
                        setDescArticulo(dataArticulo.Desc_Mueble);
                        console.log(JSON.parse(articuloApi.optionCat));
                        setCategorias(JSON.parse(articuloApi.optionCat));
                        setNomBoton('Editar Articulo');
                        setArticulo(dataArticulo);
                      })();
                }else{
                    setArticulo(null);
                    /**creara un nuevo articulo */
                }   
            /**obtener roles */
            //setIsLoading(true);
            //getRutas('',1);
            (async()=>{
                const TOKEN = await getTokenApi();
                const formdata = {
                    "token" : TOKEN,
                    "all" : "1",
                }
                const cat = await getCategoriasApi(formdata);
                var option = [];
                if(cat.error == null){
                    cat.categorias.map((c,index)=>(
                        option.push({
                            value:c.Id_Categoria,
                            label:c.Nom_Categoria
                        })             
                    ))
                    setCategoriasOptions(option);
                }
              })();
            setAuth(user);
            }
        }
        }
        init();
    }, []);
    return (
        <>
            <div className="contenidoLogin">
                <div className='articulo'>
                    <div className='cuerpoArticulo'>
                        <div className='cuadroArticulo'>
                            <div className="containerLogin">
                                {
                                    (alert == true) ?
                                    <Container>
                                        <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                                        <Alert.Heading>Advertencia</Alert.Heading>
                                        <p>
                                            {msjError}
                                        </p>
                                        </Alert>
                                    </Container>
                                    :(<></>)
                                }
                                {
                                    idArticulo != 0 ? (
                                        <h4>Editar Producto</h4>  
                                    ):(
                                        <h4>Registrar Producto</h4>
                                    )
                                }
                                {
                                    isLoading ? (
                                        <LoadingSpinner
                                        showSpinner={isLoading}
                                        />
                                        ):
                                    (
                                    <>
                                    </>
                                    )
                                }
                                <Container>
                                {
                                        idArticulo !=0 &&(
                                            <>
                                            <Row>
                                            <Container>
                                                <Alert variant="warning">
                                                <Alert.Heading>Advertencia</Alert.Heading>
                                                <p>
                                                Si deseas cambiar las imagenes debes de subir tanto la principal como las imagenes extras
                                                de lo contrario no se actualizara ninguna
                                                </p>
                                                </Alert>
                                            </Container>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Imagen Principal</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        {
                                                           articulo != null && (
                                                            <img
                                                                src={`${articulo.imagenMueble}`}
                                                                className="contenedor-imagelistProducts"
                                                                alt={articulo.Nom_Mueble}    
                                                            /> 
                                                           
                                                           )

                                                        }
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Imagenes Extras</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    articulo != null && (
                                                        map(JSON.parse(articulo.Imagenes_Mueble),(imageMuebles,index)=>(
                                                            <Col md={3}>
                                                               <img
                                                                src={`${imageMuebles.replace("./",API_URL)}`}
                                                                className="contenedor-imagelistProducts"
                                                                alt={articulo.Nom_Mueble}    
                                                                /> 
                                                            </Col>
                                                        )
                                                        )
                                                    )
                                                }
                                               
                                            </Row>
                                            </>
                                        )
                                    }
                                    <br></br>
                                    <Row>
                                        <Col md="2">
                                            <Form.Group>
                                                <Form.Label>Código del Artículo</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <Form.Control placeholder="Código del Artículo"   
                                                    id="codArticulo"
                                                    name="codArticulo"
                                                    type="text"
                                                    defaultValue={codigo}
                                                    onChange={event => setcodigo(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="2">
                                        <Form.Group>
                                                <Form.Label>Nombre del Artículo</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <Form.Control placeholder="Nombre del Artículo"   
                                                    id="nomArticulo"
                                                    name="nomArticulo"
                                                    type="text"
                                                    defaultValue={nombre}
                                                    onChange={event => setNombre(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Selecciona la(s) Categoria(s)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                        <Form.Group>
                                        <Select
                                            placeholder='Categoria(s)'
                                            options={categoriasOptions}
                                            styles={customStyles}
                                            isMulti
                                            value={categorias}
                                            getOptionLabel={ x => x.label}
                                            getOptionValue={ x => x.value}
                                            onChange={multiplesCategorias}
                                            id="categorias"
                                            />

                                        </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Existencia del Artículo</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Control placeholder="Existencia del Artículo" 
                                                    id="existenciaArticulo"
                                                    name="existenciaArticulo"
                                                    type="number"
                                                    defaultValue={exisArticulo}
                                                    onChange={event => setExisArticulo(event.target.value)}
                                                    />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h5>Precios</h5>
                                        <Col md="3">
                                            <Form.Group>
                                                <Form.Control placeholder="Precio Normal"   
                                                    id="precioArticulo"
                                                    name="precioArticulo"
                                                    type="number"
                                                    defaultValue={precioArticulo}
                                                    onChange={event => calcularPrecios(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Form.Group>
                                                <Form.Control placeholder="A 3 Meses"   
                                                    id="meses3Articulo"
                                                    name="meses3Articulo"
                                                    type="number"
                                                    defaultValue={meses3Articulo}
                                                    onChange={event => setMeses3Articulo(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Form.Group>
                                                <Form.Control placeholder="A 6 Meses"   
                                                    id="meses6Articulo"
                                                    name="meses6Articulo"
                                                    type="number"
                                                    defaultValue={meses6Articulo}
                                                    onChange={event => setMeses6Articulo(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        
                                        <Col md="3">
                                            <Form.Group>
                                                <Form.Control placeholder="De Contado"   
                                                    id="contado"
                                                    name="contado"
                                                    type="number"
                                                    defaultValue={contadoArticulo}
                                                    onChange={event => setContadoArticulo(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Descripción del Artículo</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Control placeholder="Descripción del Artículo" as="textarea" rows={11}  
                                                    id="descArticulo"
                                                    name="descArticulo"
                                                    type="text"
                                                    defaultValue={descArticulo}
                                                    onChange={event => setDescArticulo(event.target.value)}
                                                    />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Foto Principal de Artículo</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>             
                                                <input data-preview-file-type="text" className="file" type="file" name="file"  onChange={(e) => subirArchivo(e.target.files)} accept="image/png, image/jpeg"></input>                        
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                                <Form.Label>Fotos del Artículo</Form.Label>
                                                <input data-preview-file-type="text" className="file" type="file" name="files" multiple="multiple" onChange={(a) => subirArchivos(a.target.files)} accept="image/png, image/jpeg"></input>                        
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <div className="butonsLogin">
                                            <Button className='butonEntrar' onClick={updateArticulo} variant="primary" type="button">
                                                {nomBoton}
                                            </Button>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Articulo;
import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import { useNavigate } from "react-router-dom";

import { getTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';

import { getCategoriasApi,saveCategoriaApi,activarCategoriaApi } from '../../api/Categorias';

function Categoria() {
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    const [busqueda,setBusqueda] = useState("");
    const [alert, setAlert] = useState(false);
    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0);
    /**cosas para los modals principales */
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    
    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");
    const [msjError,setMsjError] = useState("");
    let navigate = useNavigate();

    /**variables del propio componente */
    const [categorias,setCateogiras] = useState(null);
    const [idCategoria,setIdCategoria] = useState(0);
    const [codCategoria,setCodCategoria] = useState(null);
    const [nomCategoria,setNomCategoria] = useState(null);
    const [descCategoria,setDescCategoria] = useState(null);
    const [activoCategoria,setActivoCategoria] = useState(null);
    var categoriaNueva = () =>{
        setIdCategoria(0);
        setCodCategoria("");
        setNomCategoria("");
        setDescCategoria("");
        setTitulomodal("Añadir Categoría");
        setBotonModal("Agregar");
        handleShow();
        setAlert(false);
    }
    var categoriaEtidar = (catEtidar) =>{
        setIdCategoria(catEtidar.Id_Categoria);
        setCodCategoria(catEtidar.Codigo_Categoria);
        setNomCategoria(catEtidar.Nom_Categoria);
        setDescCategoria(catEtidar.Desc_Categoria);
        setTitulomodal("Editar Categoría");
        setBotonModal("Editar");
        handleShow();
        setAlert(false);
    }
    var categoriaActivar = (catActivar) => {
        setIdCategoria(catActivar.Id_Categoria);
        setAlert(false);
        if (catActivar.Estatus_Categoria == 1) {
            setActivoCategoria(0);
            setTitulomodal("Desactivar Categoría");
            setBotonModal("Desactivar");
            setMensajeModal("¿Esta seguro que desea desactivar la categoría "+catActivar.Nom_Categoria+" con código "+catActivar.Codigo_Categoria+" ?");
          }else{
            setActivoCategoria(1);
            setTitulomodal("Activar Categoría");
            setBotonModal("Activar");
            setMensajeModal("¿Esta seguro que desea activar la categoría "+catActivar.Nom_Categoria+" con código "+catActivar.Codigo_Categoria+" ?");
          }
          abrirModal();
    }
    var activarDesactivarCategoria = async() =>{
        setIsLoading(true);
        const TOKEN = await getTokenApi();
        const formdata = {
            "idCategoria":idCategoria,
            "token" : TOKEN,
            "Estatus_Categoria" : activoCategoria,
        }
        const response = await activarCategoriaApi(formdata);
        if(response.succes === "succes"){
            toast.success(response.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
            setShowModal(false);
            getCategorias(busqueda,1);
        }else{
            setAlert(true);
            setMsjError(response.message);
        }
        setIsLoading(false);
    }
    var crearCategoria = async()=>{
        setIsLoading(true);
        /**logica */
        var errores = "";
        var error = 0;
        if (idCategoria.toString() == "") {
            errores+="Algo ocurrio recarga la pagina\n";
            error=1;       
        }
        if (codCategoria.toString() == "") {
            errores+="Debes ingresar el codigo de identificación de la categoría\n";
            error=1;
        }
        if (nomCategoria.toString() == "") {
            errores+="Debes ingresar el nombre de la categoría\n";
            error=1;
        }
        if (descCategoria.toString() == "") {
            errores+="Debes ingresar una breve descripción de la categoría\n";
            error=1;
        }
        if(error != 1){
            const TOKEN = await getTokenApi();
            const formdata = {
                "Codigo_Categoria": codCategoria,
                "Nom_Categoria": nomCategoria,
                "Desc_Categoria": descCategoria,
                "idCategoria":idCategoria,
                "token" : TOKEN,
            }
            const response = await saveCategoriaApi(formdata);
            if(response.succes === "succes"){
              toast.success(response.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
                handleClose();
                getCategorias(busqueda,1)
                setAlert(false);
                //getSucursales(busqueda,1);
            }else{
              setAlert(true);
              setMsjError(response.message);
              //setIsLoading(false);
            }
        }else{
            setAlert(true);
            setMsjError(errores);
        }
        /*fin  logica*/ 
        setIsLoading(false);
    }
    
    /**modal para crear y editar */
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /**modal para activar o desactivar un usuario */
    const cerrarModal = () => setShowModal(false);
    const abrirModal = () => setShowModal(true);
    /*obtener las categorias*/
    const getCategorias = useCallback( async(buscar,page_number) => {
    //console.log(buscar);
    setBusqueda(buscar);
    const TOKEN = await getTokenApi();
    const formdata = {
        "busqueda": buscar,
        "token" : TOKEN,
        "pagina" : page_number,
      }
    setCurrPage(page_number);
    var categoriasA = await getCategoriasApi(formdata);
    setTotalPaginas(categoriasA.totalPaginas); 
    setCateogiras(categoriasA.categorias);
    //setUsuarios(usariosR.usuarios); 
    setIsLoading(false); 
  },[]);
    /*Verificar si no ahy un usuario logueado*/
  useEffect(() => {
    const init = async() =>{
    const user = await getDatosUsers();
    if(user === null){
        navigate("/login", { replace: true });
    }else{
        if (user.rol!=1) {
            navigate("/", { replace: true });
        } else {
          /**obtener roles */
          setIsLoading(true);
          getCategorias('',1);
          setAuth(user);
        }
    }
    }
    init();
  }, [getCategorias]);
    var setBusquedaFuncion = (valor) =>{
        //setIsLoading(true);
        getCategorias(valor,1);
    }
    function handlePageChange(page) {
        setIsLoading(true);
        setCurrPage(page);
        //console.log("controler normal",page);
        getCategorias(busqueda,page);
        // ... do something with `page`
  }
    return (
        <>
            <div className="contenido">
                <div className="sub_contenido">
                    <div className="filtros_pag">
                        <Form.Group>
                            <InputGroup>
                                <Form.Control placeholder="Busqueda"   
                                    id="busquedaInput"
                                    name="busquedaInput"
                                    type="text"
                                    onChange={event => setBusquedaFuncion(event.target.value)}
            
                                />
                                &nbsp;
                                <Button title="Nuevo Usuario" onClick={categoriaNueva} className="botonIcono botonNuevo showmessages">Nueva Categoria</Button>
                            </InputGroup> 
                        </Form.Group>  
                    </div>
                {
                isLoading ? (
                    <LoadingSpinner
                    showSpinner={isLoading}
                    />
                    ):
                (
                <>
                </>
                )
                }
                <Table>
                    <Thead>
                        <Tr>
                            <Th className="borderTabla">Codigo de Categoría</Th>
                            <Th className="borderTabla">Nombre de Categoría</Th>
                            <Th className="borderTabla">Descripción</Th>
                            <Th className="borderTabla">Configuración</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            categorias != null &&
                            (
                                map(categorias,(categoria,index)=>(
                                        <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                            <Td className="borderTabla">{categoria.Codigo_Categoria}</Td>
                                            <Td className="borderTabla">{categoria.Nom_Categoria}</Td>
                                            <Td className="borderTabla">{categoria.Desc_Categoria}</Td>
                                            <Td className="borderTabla">
                                                <div className="configuracionBotones">
                                                    <Button title="Editar Categoría" onClick={() => categoriaEtidar(categoria)}  className='botonEditar transparente'></Button>
                                                    <Button onClick={() => categoriaActivar(categoria)} title={ categoria.Estatus_Categoria != 1 ? ('Activar'):('Desactivar') } className={ categoria.Estatus_Categoria != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>
                                                </div>
                                            </Td>
                                        </Tr>
                                    )
                                )
                            )
                        }
                    </Tbody>
                </Table>
                &nbsp;						
                <div className="alignDerecha">
                    {
                    categorias != null ?
                    ( 
                        <Pagination
                            total={totalPaginas}
                            current={currPage}
                            onPageChange={page => handlePageChange(page)}
                        />
                    ):(<p>No hay registros</p>)
                    }
                </div>
                <ToastContainer
                    transition={Flip}
                    position="bottom-center"
                    limit={1}
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                </div>
            </div>
            {/**modals para las categorias */}
            {/**modal para agregar y editar categorias */}
            <Modal  show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="tituloModal">{tituloModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                <>
                {
                    (alert == true) ?
                    <Container>
                        <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                        <Alert.Heading>Advertencia</Alert.Heading>
                        <p>
                            {msjError}
                        </p>
                        </Alert>
                    </Container>
                    :(<></>)
                }
                </>
                <Container>
                    <Form.Group>
                        <Form.Control placeholder="nombre"   
                            id="idCategoria"
                            name="idCategoria"
                            type="hidden"
                            defaultValue={idCategoria}
                            />
                    </Form.Group>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Código de Categoría</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group>
                            <Form.Control placeholder="Código de Categoría"   
                                id="codCategoria"
                                name="codCategoria"
                                type="text"
                                defaultValue={codCategoria}
                                onChange={event => setCodCategoria(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Nombre de Categoría</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group>
                            <Form.Control placeholder="Nombre de Categoría"   
                                id="nomCategoria"
                                name="nomCategoria"
                                type="text"
                                defaultValue={nomCategoria}
                                onChange={event => setNomCategoria(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Descripción de Categoría</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group>
                            <Form.Control placeholder="Descripción de Categoría" as="textarea" rows={3}  
                                id="descCategoria"
                                name="descCategoria"
                                type="text"
                                defaultValue={descCategoria}
                                onChange={event => setDescCategoria(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={crearCategoria} id="botonModal">
                    {botonModal}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                </Modal.Footer>
            </Modal>
            {/**fin modal de agregar y editar categoria */}
            {/**modal activar desactivar categoria */}
            <Modal  show={showModal} onHide={cerrarModal} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title id="tituloModal">{tituloModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                <>
                    {
                    (alert == true) ?
                        <Container>
                        <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                            <Alert.Heading>Advertencia</Alert.Heading>
                            <p>
                            {msjError}
                            </p>
                        </Alert>
                    </Container>
                    :(<></>)
                    }
                </>
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                    id="idUsuarioActivar"
                    name="idCategoria"
                    type="hidden"
                    defaultValue={idCategoria}
                    />
                </Form.Group>
                <h4>{mensajemodal}</h4>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={activarDesactivarCategoria} id="botonModal">
                    {botonModal}
                </Button>
                <Button variant="secondary" onClick={cerrarModal}>
                    Cerrar
                </Button>
                </Modal.Footer>
            </Modal>
            {/**fin de los modals */}
        </>
    );
}

export default Categoria;

import React  from "react";
import { API_URL } from "../../utils/Constant";
import { deleteProductCarApi,incrementarProductCarApi,decrementarProductCarApi,cambiarOpcionCompraApi} from "../../api/Carrito";
import './Carrito.css'
import { Button,Form } from "react-bootstrap";
import { FaTrashAlt} from "react-icons/fa";
import { AiFillPlusSquare,AiFillMinusSquare } from "react-icons/ai";
function Product(props) {
    const { product, setReloadCar} = props;
    const deleteProductCar = async () =>{
        console.log(product.Id_Mueble);
        const response = await deleteProductCarApi(product.Id_Mueble);
        if (response) setReloadCar(true);
    };
    
    const incrementarProduct = async () => {
        console.log(product.Id_Mueble);
        const response = await incrementarProductCarApi(product.Id_Mueble);
        if (response) setReloadCar(true);
    };

    const decrementarProduct = async () => {
        console.log(product.Id_Mueble);
        const response = await decrementarProductCarApi(product.Id_Mueble);
        if (response) setReloadCar(true);
    }
    const cambiarOpcionCompra =async (opcion) => {
        console.log(opcion);
        const response = await cambiarOpcionCompraApi(product.Id_Mueble,opcion);
        if (response) setReloadCar(true);
        
    }
    return(
        <div key={product.Id_Mueble} className="product">
            <div className="product-sections">
                <div className="containerImage">
                    <div className="imagecontent">
                        <img
                            src={`${product.imagenMueble}`}
                            alt={product.Nom_Mueble}  
                            className="image"
                        >
                        </img>
                    </div>
                </div>
                <div className="info">
                    <div className="infosection1">
                        <div className="infoprod">
                            <div className="info-datos">
                                <p className="nombre">
                                    {product.Nom_Mueble}
                                </p>
                                <div className="precios">
                                    <p className="currentPrice">
                                        ${
                                            (product.opcionCompra == "1" && 
                                                product.Precio_Mueble
                                            )|| (product.opcionCompra == "2" && 
                                                product.Precio3Meses_Mueble
                                            )||(product.opcionCompra == "3" && 
                                                product.Precio6Meses_Mueble
                                            )|| product.PrecioContado_Mueble
                                        }
                                      
                                    </p>
                                </div>
                            </div>
                            <div>
                                <Form.Group>
                                    <Form.Label>Forma de Pago</Form.Label>
                                    <Form.Control defaultValue={product.opcionCompra} onChange={event => cambiarOpcionCompra(event.target.value)} as="select">
                                        <option value="1">Compra Normal</option>
                                        <option value="2">A 3 Meses</option>
                                        <option value="3">A 6 Meses</option>
                                        <option value="4">De Contado</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="btnContainer">
                                <div className="selectQuantity">
                                    <AiFillPlusSquare 
                                        icon="plus" 
                                        color="#002E4B" 
                                        size={28} 
                                        className="btnQuantity" 
                                        onClick={incrementarProduct} />
                                    <div className="inputQuantity">
                                        <input type="text" className="textoInput" readOnly="readonly" defaultValue={product.quantity.toString()} />
                                    </div>
                                    <AiFillMinusSquare 
                                        icon="minus" 
                                        color="#002E4B" 
                                        size={28} 
                                        className="btnQuantity"
                                        onClick={decrementarProduct} />
                                </div>
                            </div>
                                <div className="precios">
                                    <Form.Group>
                                        <Form.Label>Total</Form.Label>
                                    </Form.Group>
                                    <p className="currentPrice">
                                        ${product.total}
                                    </p>
                                </div>
                            <div className="butdel">
                                <div className="addressbuton">
                                    <Button type="button" onClick={deleteProductCar} className="adreesicon">
                                                <FaTrashAlt ></FaTrashAlt>
                                        </Button>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div> 
            </div>           
        </div>
    )
}
export default Product;

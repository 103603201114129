import React, {useContext,useState,useEffect} from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    HashRouter,
    Navigate
} from "react-router-dom";
import Layout from '../componentes/views/Layout';
import Login from '../sesion/Login';
import Usuarios from '../componentes/usuarios/Usuarios';
import Contrato from '../componentes/contrato/Contrato';
import Contratos from '../componentes/contrato/Contratos';
import Home from '../componentes/home/Home';

import NotFound from '../componentes/views/NotFound';
import Categoria from '../componentes/categoria/Categoria';
import RutasCompra from '../componentes/RutasCompra/RutasCompra';
import Articulos from '../componentes/articulos/Articulos';
import Articulo from '../componentes/articulos/Articulo';
import VerArticulo from '../componentes/articulos/VerArticulo';
import Pagos from '../componentes/contrato/Pagos';
import VerContrato from '../componentes/contrato/VerContrato';
import LoginCliente from '../sesion/LoginCliente';
import Recaudacion from '../componentes/usuarios/Recaudacion';
function Rutas() {
    return (
        <>
        {
            <BrowserRouter>
                <Routes>
                    <Route element={<Layout/>}>
                        <Route path="*" element={<NotFound/>} />
                        <Route path='/' element={<Home/>}/>
                        <Route path='/login' element={<Login/>}/>
                        <Route path='/clienteContrato' element={<LoginCliente/>}/>
                        <Route path='/users' element={<Usuarios/>}/>
                        <Route path='/contrato' element={<Contrato/>}/>
                        <Route path='/contratos' element={<Contratos/>}/>
                        <Route path='/categorias' element={<Categoria/>}  />
                        <Route path='/rutas' element={<RutasCompra/>}  />
                        <Route path='/articulos' element={<Articulos/>} />
                        <Route path='/articulo' element={<Articulo/>} />
                        <Route path='/articuloEditar/:articuloId' element={<Articulo/>} />
                        <Route path='/verProducto/:articuloId' element={<VerArticulo/>} />
                        <Route path='/pagos/:pagoId' element={<Pagos/>} />
                        <Route path='/verContrato/:contratoId' element={<VerContrato/>} />
                        <Route path='/recaudacion/:recaudacionId' element={<Recaudacion/>} />
                        <Route path='/recaudacion/' element={<Recaudacion/>} />
                        {/*<Route path='productos' element={<Productos/>}/>*/}
                        
                        {/*<Route path="productoSucursal/:productoId" element={<ProductosInventario />}/>*/}
                    </Route>
                    {/* <Route element={<Layout/>}>
                        <Route path="/:productoId" element={<ProductosInventario />}/>
                    </Route> */}
                </Routes>
            </BrowserRouter>

        }
        </>
    )
}
export default Rutas;

import React,{useContext,useEffect, useState} from 'react';
import { Link, NavLink, Outlet, Redirect,useNavigate} from 'react-router-dom';
import { Button, Form, FormControl, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { getTokenApi, removeTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';
import { removeSesionApi } from '../../api/Usuarios';
import { getRutaApi, removeRutaApi } from '../../api/RutaElegida';

function Layout(props) {
    const [auth, setAuth] = useState(null);
    const [storageRuta,setStorageRuta] = useState(null);
    let navigate = useNavigate();
    const Logout = async() => {
        const TOKEN = await getTokenApi();
        const FormData ={
            "token" : TOKEN
        }
        const resp = await removeSesionApi(FormData);
        if (resp.status) {
            removeTokenApi();
            removeRutaApi();
            window.location.reload();
            navigate('/login');
        }
      }
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
        const user = await getDatosUsers();
        //console.log('layaout',user);
        if(user === null){
           setAuth(null)
        }else{
            setAuth(user);
        }
        const rutaApi = await getRutaApi();
        if (rutaApi == null) {
          setStorageRuta(null);
        }else{
            setStorageRuta(JSON.parse(rutaApi));
        }
    }
    init();
    }, [])




    return (
        <div>
            <Navbar collapseOnSelect expand="lg" style={{backgroundColor:"#191c2f"}}>
                    <Navbar.Brand href="/login" style={{color:"white"}}>&nbsp;&nbsp;MUEBLES SAN NICOLAS </Navbar.Brand>
                    <Navbar.Toggle style={{color:"white",background:"white"}} aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse style={{color:"white"}} id="responsive-navbar-nav">
                        <Nav  style={{color:"white"}} className="mr-auto">
                            {
                                auth ? (
                                    <>
                                        {
                                            auth.rol == 1 ? (
                                                <>
                                                <Nav.Link href="/users" style={{color:"white"}}>Usuarios</Nav.Link>
                                                <Nav.Link href="/categorias" style={{color:"white"}}>Categorias</Nav.Link>
                                                <Nav.Link href="/rutas" style={{color:"white"}}>Rutas</Nav.Link>
                                                <Nav.Link href="/articulos" style={{color:"white"}}>Articulos</Nav.Link>
                                                <Nav.Link href="/contrato" style={{color:"white"}}>Contrato</Nav.Link>
                                                <Nav.Link href="/contratos" style={{color:"white"}}>Contratos</Nav.Link>
                                                <Nav.Link href="/recaudacion" style={{color:"white"}}>Recaudacion</Nav.Link>
                                                </>
                                            ):(
                                                auth.rol == 2 ? (
                                                    <>
                                                    <Nav.Link href="/rutas" style={{color:"white"}}>Rutas</Nav.Link>
                                                    <Nav.Link href="/articulos" style={{color:"white"}}>Articulos</Nav.Link>
                                                    <Nav.Link href="/contrato" style={{color:"white"}}>Contrato</Nav.Link>
                                                    <Nav.Link href="/contratos" style={{color:"white"}}>Contratos</Nav.Link>
                                                    <Nav.Link href="/recaudacion" style={{color:"white"}}>Recaudacion</Nav.Link>
                                                    </>
                                                ):(
                                                    auth.rol == 3 ? (<>
                                                        <Nav.Link href="/rutas" style={{color:"white"}}>Rutas</Nav.Link>
                                                        <Nav.Link href="/articulos" style={{color:"white"}}>Articulos</Nav.Link>
                                                        <Nav.Link href="/contratos" style={{color:"white"}}>Contratos</Nav.Link>
                                                        <Nav.Link href="/recaudacion" style={{color:"white"}}>Recaudacion</Nav.Link>
                                                        </>
                                                    ):(
                                                        <Nav.Link href="/contratos" style={{color:"white"}}>Contratos</Nav.Link>
                                                    )
                                                )
                                            )
                                        }
                                        
                                        
                                        <Nav.Link onClick={Logout} style={{color:"white"}}>Salir</Nav.Link>
                                    </>
                                ):(
                                    <>
                                        <Nav.Link href="/clienteContrato" style={{color:"white"}}>Cliente</Nav.Link>
                                        <Nav.Link href="/login" style={{color:"white"}}>Login</Nav.Link>
                                    </>
                                )
                            }
                             
                        </Nav>
                    </Navbar.Collapse>
            </Navbar>
            <div class="usuarioNom">
                {
                    auth ? (
                        <p>Usuario:{auth.nomUsuario} </p>
                    ):<></>
                }
                
            </div>
            <div class="usuarioNom">
                {
                    storageRuta ? (
                        <p>Ruta:{storageRuta.Nom_Ruta}</p>
                    ):(<></>)
                }
            </div>
            <div className="content">
                <Outlet />
            </div>
        </div>
        )
    
}

export default Layout;

import { API_URL } from "../utils/Constant";
import { getTokenApi } from "./Token";

export async function getCategoriasApi(formdata){
    try {
        const url= `${API_URL}Categorias/getAllCategorias`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        return null;
    }
}
export async function saveCategoriaApi(formdata){
    try {
        const url= `${API_URL}Categorias/saveCategoria`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        return null;
    }
}
export async function activarCategoriaApi(formdata){
    try {
        const url= `${API_URL}Categorias/activarCategoria`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        return null;
    }
}


import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert, FormLabel} from "react-bootstrap";

function Clausulas(){
    return(
        <Row>
            <Col md={12} className='justificarTexto'>
                <p>
                    <b style={{color:"blue"}}>PRIMERA.- </b>
                    LA PARTE COMPRADORA SE COMPROMETE A CUBRIR EL IMPORTE DEL PRESENTE CONTRATO EN LA FORMA DE PAGOS DE CONVENIOS
                    EN EL MISMO, SEGÚN LO ESTIPULADO EN EL ARTICULO No. 2293 DEL CODIGO CIVIL DEL ESTADO DE PUEBLA.
                </p>  
                <p>
                    <b style={{color:"blue"}}>SEGUNDA.- </b>
                    PARA GARANTIZAR EL PAGO TOTAL DEL PRESENTE CONTRATO TANTO LA PARTE COMPRADORA COOMO EL AVAL DEBERAN SUSCRIBIR UN PAGARE UNICO A FAVOR
                    DE LA EMPRESA MUEBLES SAN NICOLAS EL CUAL SE DARA POR VENCIDO EN EL MOMENTO QUE LA PARTE COMPRADORA HAYA CUBIERTO EL IMPORTE TOTAL
                    DEL PRESENTE CONTRATO.
                </p>  
                <p>
                    <b style={{color:"blue"}}>TERCERA.- </b>
                    ES OBLIGACION DE LA PARTE COMPRADORA INFORMAR DE INMEDIATO A LA EMPRESA MUEBLES SAN NICOLAS CUALQUIER CAMBIO DE DOMICILIO QUE HUBIERA LA VIGENCIA
                    DEL PRESENTE CONTRATO, DE LO CONTRARIO SE VERA OBLIGADA A CUBRIR EL IMPORTE DE LOS GASTOS DE LOCALIZACION DE SU NUEVO DOMICILIO DE ACUERDO A LO ESTABLECIDO EN EL 
                    ARTICULO No. 2085 DEL CODIGO CIVIL DEL ESTADO DE PUEBLA.
                </p> 
                <p>
                    <b style={{color:"blue"}}>CUARTA.- </b>
                    LA PARTE COMPRADORA SE COMPORMETE A CUBRIR 3 PAGOS MINIMOS POR MES EN CASO DE 4 ATRASOS CONSECUTIVOS LA EMPRESA MUEBLES SAN NICOLAS PUEDE Y DEBE
                    PRESCINDIR DEL CREDITO ANTES OTORGADO AL CONTRATO DE RESERVA DE DOMINIO ESTIPULADO POR EL CODIGO CIVIL DEL ESTADO DE PUEBLA.
                </p> 
                <p>
                    <b style={{color:"blue"}}>QUINTA.- </b>
                    EN CASO DE RESECION DEL PRESENTE CONTRATO DESPUES DE LOS 3 DIAS HABILES A LA FIRMA DEL PRESENTE, LA PARTE COMPRADORA SE COMPROMETE A CUBRIR LOS GASTOS
                    QUE GENERE DICHA RESCISION, DE ACUERDO A LO DISPUESTO EN LOS ARTICULOS No. 28 Y 29 DE LA L.F.P.C. Y DEL 2311 DEL CODIGO CIVIL DEL ESTADO DE PUEBLA A PARTIR DE LOS 3
                    DIAS HABILES PAGARA EL 10% DE CANCELACION.
                </p> 
                <p>
                    <b style={{color:"blue"}}>SEXTA.- </b>
                    LA PARTE COMPRADORA ESTA DE ACUERDO EN PAGAR LOS INTERESES MORATORIOS PROPORCIONALES AL COSTO PACTADO EN CASO DE QUE HUBIESE ATRASO EN LA FORMA DE PAGO CONVENIDA.
                </p> 
                <p>
                    <b style={{color:"blue"}}>SEPTIMA.- </b>
                    LA PARTE COMPRADORA CON SU FIRMA EN EL PRESENTE CONTRATO SE DA POR INFORMADA DE LAS CONDICIONES DE LA OPERACION RECIBIENDO ADEMAS UNA COPIA DEL PRESENTE CONTRATO.
                </p> 
            </Col>
        </Row>
    );
}
export default Clausulas;
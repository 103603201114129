import React, {useEffect} from 'react';
import { Form ,Button, Row} from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useNavigate
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setTokenApi } from '../api/Token';
import { getDatosUsers } from '../api/Login';
import { loginApi } from '../api/Login';

function Login() {
  let navigate = useNavigate();
  
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async  (formdata) => {
        console.log('FormularioEnviado');
        try {
          const response = await loginApi(formdata);
          //console.log(response.succes);
            if(response.succes == "error"){
            //throw response.message;
            toast.error(response.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
              });

            }else{
            console.log(response);
            login(response);
          }
        } catch (error) {
            //console.log(error);
            toast.error(error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
        }
        //setLoading(false);
    }
  })
  const login = async(user) =>{
    setTokenApi(user.token);
    window.location.reload();
     /* setAuth({
       token: user.token,
       correo:user.correo,
       usern:user.usuarioNombre,
       rol:user.rol,
       id:user.idUsuario,
     });*/    
   };

  /*Verificar si no ahy un usuario logueado*/
  useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
      if(user === null){
       
      }else{
        navigate('/');
      }
    }
    init();
  }, [])
   

  return (
    <>
    
    <title>Iniciar Sesión</title>
    <div className="contenidoLogin">
      <div className='login'>
        <div className='cuerpoLogin'>
          <div className='cuadroLogin'>
            <div className="containerLogin">
              <h4>Iniciar Sesión</h4>
              <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Form.Group controlId="username">

                  <Form.Control placeholder="Usuario"
                    name="username"
                    type="text" 
                    onChange={formik.handleChange}
                    value={formik.values.username}
                  />

                </Form.Group>
              
              </Row>
              {formik.touched.email && formik.errors.email ? (
                        <>
                          <label htmlFor="username" className="error">Debe de ser un correo válido</label>
                          <br></br>
                        </>
                    ) : null}
              <br></br>
              <Row>
                <Form.Group controlId="password">

                  <Form.Control placeholder="Contraseña"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                </Form.Group>
              </Row>
              <br></br>
              <Row>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
                <div className="butonsLogin">
                  <Button className='butonEntrar' variant="primary" type="submit">
                    Entrar
                  </Button>

                </div>
              </Row>
              <br></br>
            
            </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}
  function initialValues(){
    return{
      username:'',
      password:''
    }
  }
  
  function validationSchema(){
    return{
        username: Yup.string().required(true),
        password:Yup.string().required(true)
    }
  }

  export default Login;
import { API_URL } from "../utils/Constant";
import { removeTokenApi, getTokenApi} from "./Token";
export async function loginApi(formdata){
    try {
        const url= `${API_URL}Auth/create`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(formdata),
        };
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function loginApiCliente(formdata){
    try {
        const url= `${API_URL}Auth/createCliente`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(formdata),
        };
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getMeApi(token){
    try {
        const formdata = {
            "token": token,
          }
        const url= `${API_URL}Auth/verifyToken`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(formdata),
        };
     
        const responde = await fetch(url,params);
        const result = await responde.json();
        if(result.error){
            if(result.error==="Unauthorized"){
                const response = await removeTokenApi();
                return null;
            }
            return null;
        }
        return result;
    } catch (error) {
        return null;
    }
}
export async function getDatosUsers(){
    try{
       const token = await getTokenApi();
     //  console.log('getdatosusers',token);
       if(token){
       //    console.log('entro al if');
           return await getMeApi(token);
       }else{
       // console.log('entro al else');
           return null;
       }

    }catch(error){
        return null;
    }
}

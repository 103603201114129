import { RUTA } from '../utils/Constant';
import { AsyncStorage } from 'AsyncStorage';

export async function setRutaApi(ruta){
    try {
        await AsyncStorage.setItem(RUTA, ruta);
        return true;
    } catch (error) {
        return null;
    }

}

export async function getRutaApi(){
  try {
      const ruta = await AsyncStorage.getItem(RUTA);
      if(typeof ruta === 'undefined'){
        return null;
      }else{
        return ruta;
      }
  } catch (error) {
     return null; 
  }
}

export async function removeRutaApi(){
    try {
       // console.log("este el es token para remove", TOKEN );
        const ruta = await localStorage.removeItem('@AsyncStorage:'+RUTA);
        //const token = await AsyncStorage.removeItem('@AsyncStorage:'+TOKEN);
       // console.log(token);
    return true;
    } catch (error) {
        return null;
    }
}
import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert, FormLabel} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../articulos/Carrito.css";
import "../usuarios/Usuario.css";
import {
    useNavigate,useParams
  } from "react-router-dom";
import { getDatosUsers } from '../../api/Login';
import { replace } from 'formik';
import { getTokenApi } from '../../api/Token';
import { getContratoByIdApi } from '../../api/Contrato';
import Clausulas from './Clausulas';
import { API_URL } from '../../utils/Constant';
import Pagare from './Pagare';



function VerContrato() {
    const [auth, setAuth] = useState(null);

    let params = useParams();
    let navigate = useNavigate();
    let timer = 3000;
    let delay = 3000;
    let prevent = false;
    const [isLoading, setIsLoading] = useState(false);
    const [pagosArray,setPagosArray] = useState(null);
    const [contratoDatos,setContratoDatos] = useState(null);
    const [idContrato,setIdContrato] = useState(0);
    const getAllPagos = useCallback( async() => {
        const TOKEN = await getTokenApi();
        const formdata={
            "token" : TOKEN,
            "idContrado" : params.contratoId,
        }
        const pagos = await getContratoByIdApi(formdata);
        console.log(pagos);
        setContratoDatos(pagos.contrato);
        setPagosArray(pagos.pagos);
      },[]);
    useEffect(
        () => {
            const init = async()=>{
                const user = await getDatosUsers();
                if (user === null) {
                    navigate("/",{replace:true});
                }else{
                    setAuth(user);
                    /**llamar al api para obtener todos los pagos */
                    if (params.contratoId != null) {
                        setIdContrato(params.contratoId);
                        getAllPagos();
                    } else {
                        navigate("/",{replace:true});
                    }
                }
            }
            init();
        },[]
    );
    return (
        <div className="contenido">
            <div className='contenidoSub'>
                <div className='cuadroContrato'>
                    <div className="containerLogin">
                        <div className="filtros_pag">
                        </div>
                    {
                        isLoading ? (<LoadingSpinner  showSpinner={isLoading} />):(<></>)
                    }
                    <Container>
                        {
                        contratoDatos != null && (
                            <>
                                <Container>
                                    <Row><h3>Contrato</h3></Row>
                                    {
                                        auth ? (
                                            auth.rol == 0 && (
                                                <Clausulas/>
                                            )
                                        ):(<></>)
                                    }
                                    <Row><h4>Comprador</h4></Row>
                                    <Row>

                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>SR.(A)</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].Comprador_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Dirección</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].Direccion_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                   
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Col/Poblacion</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].Colonia_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Tel:</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].TelefonoComprador_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    <Row><h4>Aval</h4></Row>
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Nombre Aval</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].NombreAval_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Dirección</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].DireccionAval_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Col/Poblacion</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].ColoniaAval_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Tel:</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].TelefonoAval_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Form.Group>
                                            <h4>Articulo(s) Comprado(s)</h4>
                                        </Form.Group>
                                    </Row>
                                    <div className="prductos-list">
                                        <Row className="row-modificada">
                                            {
                                                map(contratoDatos,(product)=>(
                                                    <Col key={product.Id_Mueble} xs={12} md={10} lg={8}>
                                                        <div key={product.Id_Mueble} className="product">
                                                            <div className="product-sections">
                                                                <div className="containerImage">
                                                                    <div className="imagecontent">
                                                                        <img
                                                                            src={`${product.Imagen_Mueble.replace("./",API_URL)}`}
                                                                            alt={product.Nom_Mueble}  
                                                                            className="image"
                                                                        >
                                                                        </img>
                                                                    </div>
                                                                </div>
                                                                <div className="info">
                                                                    <div className="infosection1">
                                                                        <div className="infoprod">
                                                                            <div className="info-datos">
                                                                                <p className="nombre">
                                                                                    {product.Nom_Mueble}
                                                                                </p>
                                                                                <div className="precios">
                                                                                    <p className="currentPrice">
                                                                                        ${
                                                                                            (product.TipoVentaActual_PC == "1" && 
                                                                                                product.Precio_Mueble
                                                                                            )|| (product.TipoVentaActual_PC == "2" && 
                                                                                                product.Precio3Meses_Mueble
                                                                                            )||(product.TipoVentaActual_PC == "3" && 
                                                                                                product.Precio6Meses_Mueble
                                                                                            )|| product.PrecioContado_Mueble
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <Form.Group>
                                                                                    <Form.Label>Forma de Pago</Form.Label>
                                                                                    {
                                                                                        (product.TipoVentaActual_PC == "1" && 
                                                                                           <Form.Label><b>Compra Normal</b></Form.Label>
                                                                                        )|| (product.TipoVentaActual_PC == "2" && 
                                                                                            <Form.Label><b>A 3 Meses</b></Form.Label>
                                                                                        )||(product.TipoVentaActual_PC == "3" && 
                                                                                            <Form.Label><b>A 6 Meses</b></Form.Label>
                                                                                        )|| <Form.Label><b>De Contado</b></Form.Label>
                                                                                    }
                                                                                </Form.Group>
                                                                            </div> 
                                                                            <div className="btnContainer">
                                                                                <div className="selectQuantity">
                                                                                    <div className="inputQuantity">
                                                                                        <input type="text" className="textoInput" readOnly="readonly" defaultValue={product.Cantidad_PC.toString()} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="precios">
                                                                                <Form.Group>
                                                                                    <Form.Label>Total</Form.Label>
                                                                                </Form.Group>
                                                                                <p className="currentPrice">
                                                                                    ${
                                                                                        (product.TipoVentaActual_PC == "1" && 
                                                                                            product.Precio_Mueble*product.Cantidad_PC
                                                                                        )|| (product.TipoVentaActual_PC == "2" && 
                                                                                            product.Precio3Meses_Mueble*product.Cantidad_PC
                                                                                        )||(product.TipoVentaActual_PC == "3" && 
                                                                                            product.Precio6Meses_Mueble*product.Cantidad_PC
                                                                                        )|| product.PrecioContado_Mueble*product.Cantidad_PC
                                                                                    }
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </div>
                                   {/**fin de articulos comprados */} 
                                   <Row>
                                        <Col md={9}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label><b>Importe total de la venta es de  ${contratoDatos[0].Importe_Venta} </b></Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                   
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Anticipo </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>${contratoDatos[0].Anticipo_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Saldo Pendiente </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>${contratoDatos[0].Saldo_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Pagos semanales de </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>${contratoDatos[0].PagoSemanal_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>A partir del</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>{contratoDatos[0].FechaInicioCobro_Venta}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='textoEnd'>
                                                <Form.Label>Dias de Cobro</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                            contratoDatos[0].NumeroDiasCobro_Venta != null &&
                                                            map(JSON.parse(contratoDatos[0].NumeroDiasCobro_Venta),(diasTrabajo)=>(
                                                            <label key={diasTrabajo.value}>{diasTrabajo.label} &nbsp;</label>
                                                            ))
                                                    }
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <h4>Vendedor {contratoDatos[0].Nom_Usuario} {contratoDatos[0].Apaterno_Usuario} {contratoDatos[0].Amaterno_Usuario	}</h4>
                                        </Form.Group>
                                    </Row>
                                    {
                                        auth.rol != 0 &&(
                                            <>
                                            <Row>
                                                <Form.Group>
                                                    <h4>Referencia Laboral</h4>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Nombre de Lugar</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Lugar_Referencia_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Dirección:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Direccion_Lugar_Referencia_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Colonia:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Colonia_Lugar_Referencia_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Celular:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Celular_Lugar_Referencia_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Group>
                                                    <h4>Referencia 1</h4>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>SR.(A)</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia1Nombre_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Dirección:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia1Direccion_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Colonia:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia1Colonia_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Celular:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia1Celular_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Group>
                                                    <h4>Referencia 2</h4>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>SR.(A)</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia2Nombre_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Dirección:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia2Direccion_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Colonia:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia2Colonia_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group className='textoEnd'>
                                                        <Form.Label>Celular:</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>{contratoDatos[0].Referencia2Celular_Venta}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Group>
                                                    <h4>Documentos o Fotos</h4>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Table>
                                                    <Thead>
                                                        <Tr>
                                                            <Th className="borderTabla">Documento</Th>
                                                            <Th className="borderTabla">Especificaciones</Th>
                                                            <Th className="borderTabla">Descargar</Th>
                                                        </Tr>
                                                        </Thead>
                                                    <Tbody>
                                                        <Tr>
                                                            <Td className="borderTabla">
                                                                Identificación Oficial del comprador
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                Identificación Oficial del comprador
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                <a 
                                                                    target='_blank'
                                                                    href={`${contratoDatos[0].Credencial_Venta.replace("./",API_URL)}`}
                                                                    >{' '}Ver</a>
                                                                  
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td className="borderTabla">
                                                                Comprobante de Domicilio del Comprador
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                Comprobante de Domicilio del comprador
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                <a target='_blank'
                                                                    href={`${contratoDatos[0].ComprobanteDomicilio_Venta.replace("./",API_URL)}`}
                                                                    >{' '}Ver</a>
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td className="borderTabla">
                                                                Foto de Domicilio del Comprador
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                Foto de Domicilio del Comprador
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                <a target='_blank'
                                                                    href={`${contratoDatos[0].FotoDomicilio_Venta.replace("./",API_URL)}`}
                                                                    >{' '}Ver</a>
                                                            </Td>
                                                        </Tr>
                                                        <Tr className="fondoTabla">
                                                            <Td className="borderTabla">
                                                                Identificación Oficial Aval
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                Identificación Oficial del Aval
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                <a target='_blank'
                                                                    href={`${contratoDatos[0].CredencialAval_Venta.replace("./",API_URL)}`}
                                                                    >{' '}Ver</a>
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td className="borderTabla">
                                                                Comprobante de Domicilio del Aval
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                Comprobante de Domicilio del Aval
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                <a target='_blank'
                                                                    href={`${contratoDatos[0].ComprobanteDomicilioAval_Venta.replace("./",API_URL)}`}
                                                                    >{' '}Ver</a>
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td className="borderTabla">
                                                                Foto de Domicilio del Aval
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                Foto de Domicilio del Aval
                                                            </Td>
                                                            <Td className="borderTabla">
                                                                <a  download={true}
                                                                target='_blank'
                                                                href={`${contratoDatos[0].FotoDomicilioAval_Venta.replace("./",API_URL)}`}
                                                                >{' '}Ver</a>
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                            </Row>
                                            </>
                                        )
                                    }
                                    <Pagare
                                    fecha={contratoDatos[0].FechaVenta_Venta}
                                    colPobCliente={contratoDatos[0].Colonia_Venta}
                                    precio={contratoDatos[0].Importe_Venta}
                                    nombreCliente={contratoDatos[0].Comprador_Venta}
                                    direccionCliente={contratoDatos[0].Direccion_Venta}
                                    celCliente={contratoDatos[0].TelefonoComprador_Venta}
                                    />
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Firma Aval</Form.Label>
                                            </Form.Group>
                                            <Form.Group>
                                                <img
                                                src={`${contratoDatos[0].FirmaAval_Venta.replace("./",API_URL)}`}
                                                alt="my signature"
                                                style={{
                                                    display: "block",
                                                    margin: "0 auto",
                                                    //border: "1px solid black",
                                                    width: "150px",
                                                
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Firma Cliente</Form.Label>
                                            </Form.Group>
                                            <Form.Group>
                                                <img
                                                src={`${contratoDatos[0].FirmaComprador_Venta.replace("./",API_URL)}`}
                                                alt="my signature"
                                                style={{
                                                    display: "block",
                                                    margin: "0 auto",
                                                    //border: "1px solid black",
                                                    width: "150px",
                                                
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        )
                        }
                        &nbsp;						
                        <div className="alignDerecha">
                            
                        </div>
                    </Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerContrato;
import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import { useNavigate } from "react-router-dom";

import { getTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';

import { activarRutasCompraApi, getRutasCompraApi, saveRutasCompraApi } from '../../api/RutasCompra';
import { getRutaApi, setRutaApi } from '../../api/RutaElegida';

function RutasCompra() {
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    const [busqueda,setBusqueda] = useState("");
    const [alert, setAlert] = useState(false);
    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0);
    /**cosas para los modals principales */
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showRuta,setShowRuta] = useState(false);
    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");
    const [msjError,setMsjError] = useState("");
    let navigate = useNavigate();

    /**variables del propio componente */
    const [rutas,setRutas] = useState(null);
    const [idRuta,setIdRuta] = useState(0);
    const [codRuta,setCodRuta] = useState(null);
    const [nomRuta,setNomRuta] = useState(null);
    const [descRuta,setDescRuta] = useState(null);
    const [activoRuta,setActivoRuta] = useState(null);
    /**variable de ruta comodin */
    const [ruta,setRuta] = useState(null);
    var rutaNueva = () =>{
        setIdRuta(0);
        setCodRuta("");
        setNomRuta("");
        setDescRuta("");
        setTitulomodal("Añadir Ruta");
        setBotonModal("Agregar");
        handleShow();
        setAlert(false);
    }
    var rutaEtidar = (rutaEtidar) =>{
        setIdRuta(rutaEtidar.Id_Ruta);
        setCodRuta(rutaEtidar.Codigo_Ruta);
        setNomRuta(rutaEtidar.Nom_Ruta);
        setDescRuta(rutaEtidar.Desc_Ruta);
        setTitulomodal("Editar Ruta");
        setBotonModal("Editar");
        handleShow();
        setAlert(false);
    }
    var rutaActivar = (rutaActivar) => {
        setIdRuta(rutaActivar.Id_Ruta);
        setAlert(false);
        if (rutaActivar.Estatus_Ruta == 1) {
            setActivoRuta(0);
            setTitulomodal("Desactivar Categoría");
            setBotonModal("Desactivar");
            setMensajeModal("¿Esta seguro que desea desactivar la ruta "+rutaActivar.Nom_Ruta+" con código "+rutaActivar.Codigo_Ruta+" ?");
          }else{
            setActivoRuta(1);
            setTitulomodal("Activar Categoría");
            setBotonModal("Activar");
            setMensajeModal("¿Esta seguro que desea activar la ruta "+rutaActivar.Nom_Ruta+" con código "+rutaActivar.Codigo_Ruta+" ?");
          }
          abrirModal();
    }
    var activarDesactivarRuta = async() =>{
        setIsLoading(true);
        const TOKEN = await getTokenApi();
        const formdata = {
            "idRuta":idRuta,
            "token" : TOKEN,
            "Estatus_Ruta" : activoRuta,
        }
        const response = await activarRutasCompraApi(formdata);
        if(response.succes === "succes"){
            toast.success(response.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
            setShowModal(false);
            getRutas(busqueda,1);
        }else{
            setAlert(true);
            setMsjError(response.message);
        }
        setIsLoading(false);
    }
    var crearRuta = async()=>{
        setIsLoading(true);
        /**logica */
        var errores = "";
        var error = 0;
        if (idRuta.toString() == "") {
            errores+="Algo ocurrio recarga la pagina\n";
            error=1;       
        }
        if (codRuta.toString() == "") {
            errores+="Debes ingresar el codigo de identificación de la categoría\n";
            error=1;
        }
        if (nomRuta.toString() == "") {
            errores+="Debes ingresar el nombre de la categoría\n";
            error=1;
        }
        if (descRuta.toString() == "") {
            errores+="Debes ingresar una breve descripción de la categoría\n";
            error=1;
        }
        if(error != 1){
            const TOKEN = await getTokenApi();
            const formdata = {
                "Codigo_Ruta": codRuta,
                "Nom_Ruta": nomRuta,
                "Desc_Ruta": descRuta,
                "idRuta":idRuta,
                "token" : TOKEN,
            }
            const response = await saveRutasCompraApi(formdata);
            if(response.succes === "succes"){
              toast.success(response.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
                handleClose();
                getRutas(busqueda,1)
                setAlert(false);
                //getSucursales(busqueda,1);
            }else{
              setAlert(true);
              setMsjError(response.message);
              //setIsLoading(false);
            }
        }else{
            setAlert(true);
            setMsjError(errores);
        }
        /*fin  logica*/ 
        setIsLoading(false);
    }
    
    /**modal para crear y editar */
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /**modal para activar o desactivar un usuario */
    const cerrarModal = () => setShowModal(false);
    const abrirModal = () => setShowModal(true);
    /**modal para activar o desactivar un usuario */
    const cerrarModalRuta = () => setShowRuta(false);
    const abrirModalRuta = () => setShowRuta(true);
    /*obtener las categorias*/
    const getRutas = useCallback( async(buscar,page_number) => {
    //console.log(buscar);
    setBusqueda(buscar);
    const TOKEN = await getTokenApi();
    const formdata = {
        "busqueda": buscar,
        "token" : TOKEN,
        "pagina" : page_number,
        "opcTodos":"0",
      }
    setCurrPage(page_number);
    var rutascompra = await getRutasCompraApi(formdata);
    setTotalPaginas(rutascompra.totalPaginas); 
    setRutas(rutascompra.rutas);
    //setUsuarios(usariosR.usuarios); 
    setIsLoading(false); 
  },[]);
    /*Verificar si no ahy un usuario logueado*/
  useEffect(() => {
    const init = async() =>{
    const user = await getDatosUsers();
    if(user === null){
        navigate("/login", { replace: true });
    }else{
        if (user.rol!=1 && user.rol!=2 && user.rol!=3) {
            navigate("/", { replace: true });
        } else {
          /**obtener roles */
          setIsLoading(true);
          getRutas('',1);
          setAuth(user);
        }
    }
    }
    init();
  }, [getRutas]);
    var setBusquedaFuncion = (valor) =>{
        //setIsLoading(true);
        getRutas(valor,1);
    }
    function handlePageChange(page) {
        setIsLoading(true);
        setCurrPage(page);
        //console.log("controler normal",page);
        getRutas(busqueda,page);
        // ... do something with `page`
  }
  var seleccionarRuta = async(varRuta) => {
    setRuta(varRuta);
    setAlert(false);
    setBotonModal("Seleccionar");
    setMensajeModal("¿Seguro que quiere elegir la ruta "+varRuta.Nom_Ruta+" con código "+varRuta.Codigo_Ruta+" ?");
    abrirModalRuta();
  }
  var guardarRuta = async()=>{
   console.log(ruta);
   setRutaApi(JSON.stringify(ruta));
   const rutaApi = await getRutaApi();
   console.log(JSON.parse(rutaApi).Nom_Ruta);
   navigate('/articulos');
  }
    return (
        <>
            <div className="contenido">
                <div className="sub_contenido">
                    <div className="filtros_pag">
                        <Form.Group>
                            <InputGroup>
                                <Form.Control placeholder="Busqueda"   
                                    id="busquedaInput"
                                    name="busquedaInput"
                                    type="text"
                                    onChange={event => setBusquedaFuncion(event.target.value)}
            
                                />
                                &nbsp;
                                {
                                    auth ? (
                                        auth.rol == 1 &&(
                                            <Button title="Nuevo Usuario" onClick={rutaNueva} className="botonIcono botonNuevo showmessages">Nueva Ruta</Button>
                                        )
                                    ):(
                                        <></>
                                    )
                                }
                            </InputGroup> 
                        </Form.Group>  
                    </div>
                {
                isLoading ? (
                    <LoadingSpinner
                    showSpinner={isLoading}
                    />
                    ):
                (
                <>
                </>
                )
                }
                {
                    auth ? (
                        auth.rol != 1 &&(
                            <Container>
                                <h3>Selecciona una ruta</h3>
                            </Container>
                        )
                    ):(
                        <></>
                    )
                }
                <Table>
                    <Thead>
                        <Tr>
                            <Th className="borderTabla">Codigo de Ruta</Th>
                            <Th className="borderTabla">Nombre de Ruta</Th>
                            <Th className="borderTabla">Descripción</Th>
                            <Th className="borderTabla">Configuración</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            rutas != null &&
                            (
                                map(rutas,(r,index)=>(
                                        <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                            <Td className="borderTabla">{r.Codigo_Ruta}</Td>
                                            <Td className="borderTabla">{r.Nom_Ruta}</Td>
                                            <Td className="borderTabla">{r.Desc_Ruta}</Td>
                                            <Td className="borderTabla">
                                                <div className="configuracionBotones">
                                                    {
                                                        auth.rol == 1 &&(
                                                            <>
                                                            <Button title="Editar Categoría" onClick={() => rutaEtidar(r)}  className='botonEditar transparente'></Button>
                                                            <Button onClick={() => rutaActivar(r)} title={ r.Estatus_Ruta != 1 ? ('Activar'):('Desactivar') } className={ r.Estatus_Ruta != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>
                                                            </>
                                                        ) 
                                                    }
                                                    {
                                                        auth.rol != 1 && auth.rol != 0 &&(
                                                            <Button onClick={() => seleccionarRuta(r)} title="Seleccionar" className='send-check-custom transparente' type='button' name='button'></Button>
                                                        )
                                                    }
                                                </div>
                                            </Td>
                                        </Tr>
                                    )
                                )
                            )
                        }
                    </Tbody>
                </Table>
                &nbsp;						
                <div className="alignDerecha">
                    {
                    rutas != null ?
                    ( 
                        <Pagination
                            total={totalPaginas}
                            current={currPage}
                            onPageChange={page => handlePageChange(page)}
                        />
                    ):(<p>No hay registros</p>)
                    }
                </div>
                <ToastContainer
                    transition={Flip}
                    position="bottom-center"
                    limit={1}
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                </div>
            </div>
            {/**modals para las categorias */}
            {/**modal para agregar y editar categorias */}
            <Modal  show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="tituloModal">{tituloModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                <>
                {
                    (alert == true) ?
                    <Container>
                        <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                        <Alert.Heading>Advertencia</Alert.Heading>
                        <p>
                            {msjError}
                        </p>
                        </Alert>
                    </Container>
                    :(<></>)
                }
                </>
                <Container>
                    <Form.Group>
                        <Form.Control placeholder="nombre"   
                            id="idCategoria"
                            name="idCategoria"
                            type="hidden"
                            defaultValue={idRuta}
                            />
                    </Form.Group>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Código de la Ruta</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group>
                            <Form.Control placeholder="Código de la Ruta"   
                                id="codRuta"
                                name="codRuta"
                                type="text"
                                defaultValue={codRuta}
                                onChange={event => setCodRuta(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Nombre de la Ruta</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group>
                            <Form.Control placeholder="Nombre de la Ruta"   
                                id="nomRuta"
                                name="nomRuta"
                                type="text"
                                defaultValue={nomRuta}
                                onChange={event => setNomRuta(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Descripción de la Ruta</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group>
                            <Form.Control placeholder="Descripción de la Ruta" as="textarea" rows={3}  
                                id="descRuta"
                                name="descRuta"
                                type="text"
                                defaultValue={descRuta}
                                onChange={event => setDescRuta(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={crearRuta} id="botonModal">
                    {botonModal}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                </Modal.Footer>
            </Modal>
            {/**fin modal de agregar y editar categoria */}
            {/**modal activar desactivar categoria */}
            <Modal  show={showModal} onHide={cerrarModal} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title id="tituloModal">{tituloModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                <>
                    {
                    (alert == true) ?
                        <Container>
                        <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                            <Alert.Heading>Advertencia</Alert.Heading>
                            <p>
                            {msjError}
                            </p>
                        </Alert>
                    </Container>
                    :(<></>)
                    }
                </>
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                    id="idUsuarioActivar"
                    name="idRuta"
                    type="hidden"
                    defaultValue={idRuta}
                    />
                </Form.Group>
                <h4>{mensajemodal}</h4>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={activarDesactivarRuta} id="botonModal">
                    {botonModal}
                </Button>
                <Button variant="secondary" onClick={cerrarModal}>
                    Cerrar
                </Button>
                </Modal.Footer>
            </Modal>

            {/**modal seleccionar ruta y guardarla */}
            <Modal  show={showRuta} onHide={cerrarModalRuta} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title id="tituloModal">{tituloModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                <>
                    {
                    (alert == true) ?
                        <Container>
                        <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                            <Alert.Heading>Advertencia</Alert.Heading>
                            <p>
                            {msjError}
                            </p>
                        </Alert>
                    </Container>
                    :(<></>)
                    }
                </>
                <h4>{mensajemodal}</h4>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={guardarRuta} id="botonModal">
                    {botonModal}
                </Button>
                <Button variant="secondary" onClick={cerrarModalRuta}>
                    Cerrar
                </Button>
                </Modal.Footer>
            </Modal>
            
            {/**fin de los modals */}
        </>
    );
}

export default RutasCompra;

import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import { useNavigate } from "react-router-dom";

import { getTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';

import logoUsuario  from "../../assets/logos/usuario.png"

import { getRolesApi,getUsuariosApi,saveUsuarioApi,activarUsuarioApi, resetUserApi } from '../../api/Usuarios';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    
    color: state.isSelected ? '#black' : 'black',
    backgroundColor: state.isSelected ? '#5243aa' : 'white',
    backgroundColor: state.isFocused ? '#e8f0fe' : 'white',

  }),
  control: (provided) => ({
    ...provided,
   // marginTop: "5%",
  })
}

function Usuarios() {
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useState(null);
  let navigate = useNavigate();
  
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
 
  const [tituloModal,setTitulomodal] = useState("");
  const [botonModal,setBotonModal] = useState("");
  const [mensajemodal,setMensajeModal] = useState("");
  const [alert, setAlert] = useState(false);


  const [usuarios, setUsuarios] = useState(null);
  const [rol, setRoles] = useState(null);
  const [idUsuario,setIdUsuario] = useState(0);
  const [nombreUsuario,setNombreUsuario] = useState(null);
  const [apaternoUsuario,setApaternoUsuario] = useState(null);
  const [amaternoUsuario,setAmaternoUsuario] = useState(null);
  const [userUsuario,setUserUsuario] = useState(null);
  const [contraseniaUsuario,setContraseniaUsuario] = useState(null);
  const [repeatcontraseniaUsuario,setRepeatContraseniaUsuario] = useState(null);
  const [idRolUsuario,setIdRolUsuario] = useState("");
  const [defaultSelectRol,setDefaultValorSelectRol] = useState(null);
  const [busqueda,setBusqueda] = useState("");
  const [msjError,setMsjError] = useState("");

  /**activar usuario */
  const[activoUsuario,setActivoUsuario] = useState(0);
  /*cosas para usar la pagiancion*/
  const [tagList, setTagList] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPaginas,setTotalPaginas] = useState(0);

  /*obtener a los usuarios*/
  const getUsuarios = useCallback( async(buscar,page_number) => {
    //console.log(buscar);
    setBusqueda(buscar);
    const TOKEN = await getTokenApi();
    const formdata = {
        "busqueda": buscar,
        "token" : TOKEN,
        "pagina" : page_number,
      }
    setCurrPage(page_number);
    var usariosR = await getUsuariosApi(formdata);
    //console.log(usuario.totalPaginas);
    setTotalPaginas(usariosR.totalPaginas); 
    setUsuarios(usariosR.usuarios); 
    setIsLoading(false); 
  },[]);
  
  var usuarioNuevo = () => {
      setIdUsuario(0);
      setDefaultValorSelectRol(null);
      setUserUsuario("");
      setNombreUsuario("");
      setApaternoUsuario("");
      setAmaternoUsuario("");
      setContraseniaUsuario("");
      setContraseniaUsuario("");
      setRepeatContraseniaUsuario("");
      setTitulomodal("Añadir");
      setBotonModal("Agregar");
      handleShow();
      setAlert(false);
    }
  function handlePageChange(page) {
        setIsLoading(true);
        setCurrPage(page);
        //console.log("controler normal",page);
        getUsuarios(busqueda,page);
        // ... do something with `page`
  }
  /* select multiple opciones */
  var multipleSelect = (e) =>{
    setDefaultValorSelectRol(e);
    setIdRolUsuario(e.value);
    //console.log(e);
  }

 var editarUsuario = (userEditar) =>{
  setAlert(false);
  setIdUsuario(userEditar.Id_Usuario);
  map(rol,(r) =>{
    if (r.value == userEditar.IdRol_Usuario) {
      setDefaultValorSelectRol(r);
      setIdRolUsuario(r.value);
    }
  });
  setUserUsuario(userEditar.User_Usuario);
  setNombreUsuario(userEditar.Nom_Usuario);
  setApaternoUsuario(userEditar.Apaterno_Usuario);
  setAmaternoUsuario(userEditar.Amaterno_Usuario);
  setContraseniaUsuario("");
  setRepeatContraseniaUsuario("");
  setTitulomodal("Editar");
  setBotonModal("Editar");
  handleShow();
 }
 var activarUsuario = (userActivar) =>{
  setIdUsuario(userActivar.Id_Usuario);
  setAlert(false);
  if (userActivar.Estatus_Usuario == 1) {
    setActivoUsuario(0);
    setTitulomodal("Desactivar");
    setBotonModal("Desactivar");
    setMensajeModal("¿Esta seguro que desea desactivar el usuario "+userActivar.Nom_Usuario+" "+userActivar.Apaterno_Usuario+" "+userActivar.Amaterno_Usuario+" ?");
  }else{
    setActivoUsuario(1);
    setTitulomodal("Activar");
    setBotonModal("Activar");
    setMensajeModal("¿Esta seguro que desea activar el usuario "+userActivar.Nom_Usuario+" "+userActivar.Apaterno_Usuario+" "+userActivar.Amaterno_Usuario+" ?");
  }
  abrirModal();
 }
   
var crearUsuario = async() =>{
  setIsLoading(true);
  const validaPassword = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
  var errores = "";
  var error = 0;
  if(idUsuario.toString() == ""){
    errores+="Algo ocurrio recarga la pagina\n";
    error=1;
  }else{
      if (idUsuario.toString() === "0") {
          /*Validar contraseñas*/
          if(contraseniaUsuario.toString() == "" || (!validaPassword.test(contraseniaUsuario.toString()))){
              errores+="La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula NO puede tener otros símbolos.\n";
              error=1;
          }
          if(contraseniaUsuario.toString() != repeatcontraseniaUsuario.toString()){
              errores+="Las constraseñas no coinciden\n";
              error=1;
          }
      }else{
          /*preguntar al usuario si desea cambiar las contraseña de ser asi se verifica que las contraseñas
          coincidan de lo contrario se ponen en blanco las contraseñas*/
          if(contraseniaUsuario.toString() !== "" || repeatcontraseniaUsuario.toString() !== ""){
            errores+= 'Si quiere cambiar las contraseñas ambas deben coincidir de lo contrario deje los campos en blanco';
            if(contraseniaUsuario.toString() == "" || (!validaPassword.test(contraseniaUsuario.toString()))){
              errores+="La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula NO puede tener otros símbolos.\n";
              error=1;
              }
              if(contraseniaUsuario.toString() != repeatcontraseniaUsuario.toString()){
                  errores+="Las constraseñas no coinciden\n";
                  error=1;
              }
          }
      }
  }
  if(nombreUsuario.toString() == ""){
    errores+="Debes ingresar el nombre del trabajador\n";
    error=1;
  }
  if(apaternoUsuario.toString() == ""){
    errores+="Debes ingresar el apellido paterno del trabajador\n";
    error=1;
  }
  if(amaternoUsuario.toString() == ""){
    errores+="Debes ingresar el apellido materno del trabajador\n";
    error=1;
  }
  if(userUsuario.toString() == ""){
      errores+="Debes ingresar el nombre de usuario del trabajador con el que podra acceder a la plataforma\n";
      error=1;
  }
  if(idRolUsuario.toString() == ""){
    errores+="Debes de seleccionar el rol del trabajador\n";
    error=1;
  }
  if(error != 1){
    const TOKEN = await getTokenApi();
    const formdata = {
        "Nom_Usuario": nombreUsuario,
        "Apaterno_Usuario": apaternoUsuario,
        "Amaterno_Usuario": amaternoUsuario,
        "User_Usuario": userUsuario,
        "Contrasenia_Usuario":contraseniaUsuario,
        "IdRol_Usuario":idRolUsuario,
        "idUsuario":idUsuario,
        "passwordRepeat":repeatcontraseniaUsuario,
        "token" : TOKEN,
      }
    const response = await saveUsuarioApi(formdata);
    if(response.succes === "succes"){
      toast.success(response.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
        });
        handleClose();
        getUsuarios(busqueda,1)
        setAlert(false);
        //getSucursales(busqueda,1);
    }else{
      setAlert(true);
      setMsjError(response.message);
      //setIsLoading(false);
    }

  }else{
    setAlert(true);
    setMsjError(errores);
  }
  setIsLoading(false);
}
var activarDesactivarUsuario  = async() =>{
  setIsLoading(true);
  const TOKEN = await getTokenApi();
  const formdata = {
      "idUsuario":idUsuario,
      "token" : TOKEN,
      "Estatus_Usuario" : activoUsuario,
    }
  // console.log('activarDesactivarUsuario',formdata);
   const response = await activarUsuarioApi(formdata);
    if(response.succes === "succes"){
      toast.success(response.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
          });
          setShowModal(false);
          getUsuarios(busqueda,1);
  }else{
    setAlert(true);
    setMsjError(response.message);
  }
  setIsLoading(false);
}

  /**modal para crear y editar */
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  /**modal para activar o desactivar un usuario */
  const cerrarModal = () => setShowModal(false);
  const abrirModal = () => setShowModal(true);

  /*Verificar si no ahy un usuario logueado*/
  useEffect(() => {
    const init = async() =>{
    const user = await getDatosUsers();
    if(user === null){
        navigate("/login", { replace: true });
    }else{
        if (user.rol!=1) {
            navigate("/", { replace: true });
        } else {
          /**obtener roles */
          (async()=>{
            const TOKEN = await getTokenApi();
            const formdata = {
                "token" : TOKEN,
            }
            const roles = await getRolesApi(formdata);
            var option = [];
            if(roles.error == null){
                roles.roles.map((role,index)=>(
                    option.push({
                        value:role.Id_Rol,
                        label:role.Desc_Rol
                    })             
                ))
                setRoles(option);
            }
          })();
          setIsLoading(true);
          getUsuarios('',1);
          setAuth(user);
        }
    }
    }
    init();
  }, [getUsuarios]);
    var setBusquedaFuncion = (valor) =>{
      //setIsLoading(true);
    getUsuarios(valor,1);
  }
  return (
    <>
      <div className="contenido">
        <div className="sub_contenido">
          <div className="filtros_pag">
            <Form.Group>
              <InputGroup>
                  <Form.Control placeholder="Busqueda"   
                      id="busquedaInput"
                      name="busquedaInput"
                      type="text"
                      onChange={event => setBusquedaFuncion(event.target.value)}

                  />
                  &nbsp;
                  <Button title="Nuevo Usuario" onClick={usuarioNuevo} className="botonIcono botonNuevo showmessages">Nuevo Usuario</Button>
              </InputGroup> 
            </Form.Group>  
          </div>
          {
            isLoading ? (
                <LoadingSpinner
                showSpinner={isLoading}
                />
                ):
            (
            <>
            </>
            )
          }
          <Table>
            <Thead>
              <Tr>
                <Th className="borderTabla"></Th>
                <Th className="borderTabla">Nombre de Usuario</Th>
                <Th className="borderTabla">Nombre</Th>
                <Th className="borderTabla">Rol</Th>
                <Th className="borderTabla">Configuración</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                usuarios != null &&
                  (
                    map(usuarios,(user,index)=>(
                      <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                        <Td className="borderTabla"><img src={logoUsuario} className="imgUsuarios" alt=""/></Td>
                        <Td className="borderTabla">{user.User_Usuario}</Td>
                        <Td className="borderTabla">{user.Nom_Usuario+" "+user.Apaterno_Usuario+" "+user.Amaterno_Usuario}</Td>
                        <Td className="borderTabla">{user.Nom_Rol}</Td>
                        <Td className="borderTabla">
                          <div className="configuracionBotones">
                            <Button title="Editar Usuario" onClick={() => editarUsuario(user)}  className='botonEditar transparente'></Button>
                            <Button onClick={() => activarUsuario(user)} title={ user.Estatus_Usuario != 1 ? ('Activar'):('Desactivar') } className={ user.Estatus_Usuario != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>
                            <a title="Recaudacion" href={`/recaudacion/${user.Id_Usuario}`} className='botonPrecio transparente'></a>
                          </div>
                        </Td>
                      </Tr>
                      )
                    )
                  )
              }
            </Tbody>
          </Table>
          &nbsp;						
          <div className="alignDerecha">
            {
              usuarios != null ?
              ( 
              <Pagination
                  total={totalPaginas}
                  current={currPage}
                  onPageChange={page => handlePageChange(page)}
              />
              ):
              (
              <p>No hay registros</p>
              )
            }
          </div>
          <ToastContainer
            transition={Flip}
            position="bottom-center"
            limit={1}
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
      {/**modals */}
      <Modal  show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
            <Modal.Title id="tituloModal">{tituloModal} Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <>
          {
            (alert == true) ?
              <Container>
                <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                  <Alert.Heading>Advertencia</Alert.Heading>
                  <p>
                    {msjError}
                  </p>
                </Alert>
            </Container>
            :(<></>)
          }
          </>
          <Container>
            <Form.Group>
              <Form.Control placeholder="nombre"   
                id="idUsuario"
                name="idUsuario"
                type="hidden"
                defaultValue={idUsuario}
                />
            </Form.Group>
            <Row>
              <Col md={4}>
                <Form.Group>
                    <Form.Label>Nombre de Usuario</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control placeholder="Nombre de Usuario"   
                    id="userUsuario"
                    name="userUsuario"
                    type="text"
                    defaultValue={userUsuario}
                    onChange={event => setUserUsuario(event.target.value)}
                    />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                    <Form.Label>Nombre</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control placeholder="Nombre del Trabajador"   
                    id="nombre"
                    name="nombre"
                    type="text"
                    defaultValue={nombreUsuario}
                    onChange={event => setNombreUsuario(event.target.value)}
                    />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                    <Form.Label>Apellido Paterno</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control placeholder="Apellido Paterno"   
                    id="apaternoUsuario"
                    name="apaternoUsuario"
                    type="text"
                    defaultValue={apaternoUsuario}
                    onChange={event => setApaternoUsuario(event.target.value)}
                    />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                    <Form.Label>Apellido Materno</Form.Label>
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Control placeholder="Apellido Materno"   
                    id="amaternoUsuario"
                    name="amaternoUsuario"
                    type="text"
                    defaultValue={amaternoUsuario}
                    onChange={event => setAmaternoUsuario(event.target.value)}
                    />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                  <Form.Group>
                      <Form.Label>Rol</Form.Label>
                  </Form.Group>
              </Col>
              <Col md={8}>
              <Form.Group>
                  <Select
                      placeholder='Rol'
                      options={rol}
                      styles={customStyles}
                      id="rolSelect"
                      defaultValue={defaultSelectRol}
                      onChange={multipleSelect} />
              </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                  <Form.Group>
                      <Form.Label>Contraseña</Form.Label>
                  </Form.Group>
              </Col>
              <Col md={8}>
              <Form.Group>
                  <Form.Control placeholder="Contraseña"   
                    id="contraseniaUsuario"
                    name="contraseniaUsuario"
                    type="password"
                    defaultValue={contraseniaUsuario}
                    onChange={event => setContraseniaUsuario(event.target.value)}
                    />
              </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                  <Form.Group>
                      <Form.Label>Repetir Contraseña</Form.Label>
                  </Form.Group>
              </Col>
              <Col md={8}>
              <Form.Group>
                  <Form.Control placeholder="Contraseña"   
                    id="repeatcontraseniaUsuario"
                    name="repeatcontraseniaUsuario"
                    type="password"
                    defaultValue={repeatcontraseniaUsuario}
                    onChange={event => setRepeatContraseniaUsuario(event.target.value)}
                    />
              </Form.Group>
              </Col>
            </Row>
          </Container>
          </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={crearUsuario} id="botonModal">
                        {botonModal}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
      </Modal>
      {/**modal activar desactivar usuario */}
      <Modal  show={showModal} onHide={cerrarModal} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title id="tituloModal">{tituloModal} Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <>
            {
              (alert == true) ?
                <Container>
                  <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                    <Alert.Heading>Advertencia</Alert.Heading>
                    <p>
                      {msjError}
                    </p>
                  </Alert>
              </Container>
              :(<></>)
            }
          </>
          <Form.Group>
            <Form.Control placeholder="nombre"   
              id="idUsuarioActivar"
              name="idUsuario"
              type="hidden"
              defaultValue={idUsuario}
            />
          </Form.Group>
          <h4>{mensajemodal}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={activarDesactivarUsuario} id="botonModal">
              {botonModal}
          </Button>
          <Button variant="secondary" onClick={cerrarModal}>
              Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


      {/**fin modals */}
    </>

  );
}
  
  export default Usuarios;
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {map} from 'lodash';
import './VerProducto.css'
import { API_URL } from '../../utils/Constant';
export default function Gallery(props) {
    const {imagenes,imagen} = props;
    //console.log(imagenes);
    return (
            <Carousel 
                showArrows={true} 
                axis={'horizontal'}  
                dynamicHeight={false} 
                showIndicators={true} 
                showThumbs={true}
                thumbWidth={50}
                className="imagen"
            > 
                <img
                    src={`${imagen}`}
                /> 
                {map(JSON.parse(imagenes), (imagen,index)=>(
                    <img key={index} alt="img" src={`${imagen.replace("./",API_URL)}`}  />
                ))} 
            </Carousel>
    )
}

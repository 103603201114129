import React, {useContext,useRef,useState,useEffect,useCallback} from 'react';
import { Button ,Modal, Container, Row, Col, Form,Alert} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import SignatureCanvas from "react-signature-canvas";
import { findByCatIdApi } from '../../api/Articulos';
import { getTokenApi } from '../../api/Token';
import { deleteCartApi, getProductCarApi } from '../../api/Carrito';
import {map} from 'lodash';
import Product from '../articulos/Product';
import { FileUploader } from '../widgets/FileUploader';
import Webcam from "react-webcam";
import Select from 'react-select';
import Pagare from './Pagare';
import LoadingSpinner from '../views/LoadingSpinner';
import { saveContratoApi } from '../../api/Contrato';
import { useNavigate } from 'react-router-dom';
import { getDatosUsers } from '../../api/Login';
import { getRutasCompraApi } from '../../api/RutasCompra';
import Clausulas from './Clausulas';
import { getRutaApi } from '../../api/RutaElegida';
const videoConstraints = {
    width: 400,
    height: 800,
    facingMode: "user"
 }
 const diasOptions = [
    { value: 'Lunes', label: 'Lunes' },
    { value: 'Martes', label: 'Martes' },
    { value: 'Miercoles', label: 'Miercoles' },
    //{ value: 'Jueves', label: 'Jueves' }, 
    //{ value: 'Viernes', label: 'Viernes' }, 
    { value: 'Sabado', label: 'Sabado' },
    { value: 'Domingo', label: 'Domingo' }
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      
      color: state.isSelected ? 'yellow' : 'black',
      backgroundColor: state.isSelected ? 'green' : 'white',
    }),
    control: (provided) => ({
      ...provided,
     // marginTop: "5%",
    })
  }

function Contrato() {
    const [rutaApi,setRutaApi] = useState(null)
    const [rutas,setRutas] = useState(null);
    /**variables del formualrio */
    const [nombreCliente,setNombreCliente] = useState("");
    const [direccionCliente,setDireccionCliente] = useState("");
    const [colPobCliente,setColPobCliente] = useState("");
    const [celCliente,setCelCliente] = useState("");
    const [nombreAval,setNombreAval] = useState("");
    const [direccionAval,setDireccionAval] = useState("");
    const [colPobAval,setColPobAval] = useState("");
    const [celAval,setCelAval] = useState("");
    const [anticipo,setAnticipo] = useState("");
    const [saldoPendiente,setSaldoPendiente] = useState("");
    const [pagoSemanal,setPagoSemanal] = useState("");
    const [fechaInicioPago,setFechaInicioPago] = useState("");
    const [dias,setDiasValor] = useState(null);
    const [nombreLugar,setNombreLugar] = useState("");
    const [direccionLugar,setDireccionLugar] = useState("");
    const [coloniaLugar,setColoniaLugar] = useState("");
    const [celLugar,setCelLugar] = useState("");
    const [referencia1Nombre,setReferencia1Nombre] = useState("");
    const [referencia1Direccion,setReferencia1Direccion] = useState("");
    const [referencia1Colonia,setReferencia1Colonia] = useState("");
    const [referencia1Celular,setReferencia1Celular] = useState("");
    const [referencia2Nombre,setReferencia2Nombre] = useState("");
    const [referencia2Direccion,setReferencia2Direccion] = useState("");
    const [referencia2Colonia,setReferencia2Colonia] = useState("");
    const [referencia2Celular,setReferencia2Celular] = useState("");
    const [ruta,setOpcionruta] = useState("0");
    /**fin variables del fomulario */
    /**opciones para los archivos */
    const [opcionCredencialComprador,setOpcionCrecencialComprador] = useState(0);
    const [opcionComprobanteDomicilioComprador,setOpcionComprobanteDomicilioComprador] = useState(0);
    const [opcionFotoDomicilioComprador,setOpcionFotoDomicilioComprador] = useState(0);
    const [opcionCredencialAval,setOpcionCredencialAval] = useState(0);
    const [opcionComprobanteDomicilioAval,setOpcionComprobanteDomicilioAval] = useState(0);
    const [opcionFotoDomicilioAval,setOpcionFotoDomicilioAval] = useState(0);
    /**fin opciones para los archivos */
    const [isLoading, setIsLoading] = useState(false);

    const [imagenAval, setImagenAval] = useState(""); // create a state that will contain our image url
    const [imagenCliente, setImagenCliente] = useState(""); // create a state that will contain our image url

    const sigCanvasAval = useRef({});
    const sigCanvasCliente = useRef({});

    const [cart,setCar] = useState(null);
    const [isCar,setIsCar] = useState(false);

    const [products,setProducts] = useState(null);
    const [precio,setpreciofinal] = useState(0);
    const [reloadCar,setReloadCar] = useState(false);
    const [disabledButton,setDisabledButton] = useState(true);

    /**para los input tipo */
    //const [credencial, setCredencial] = useState("");
    const handleFileCredencial = (file) => {
        setOpcionCrecencialComprador(1);
        //setCredencial(file);
        setFotoCredencial(file);
    };
    //const [credencialAval, setCredencialAval] = useState("");
    const handleFileCredencialAval = (file) => {
        setOpcionCredencialAval(1);
        //setCredencialAval(file);
        setsetFotoCredencialAval(file);
    };
    //const [domiciloFoto, setDomicilioFoto] = useState("");
    const handleFileDomicilioFoto = (file) => {
        setOpcionFotoDomicilioComprador(1);
        //setDomicilioFoto(file);
        setDomicilioFotoFoto(file);
    };
    //const [domiciloFotoAval, setDomicilioFotoAval] = useState("");
    const handleFileDomicilioFotoAval = (file) => {
        setOpcionFotoDomicilioAval(1);
        //setDomicilioFotoAval(file);
        setDomicilioFotoFotoAval(file);
    };
    //const [domicilioArchivo, setDomicilioArchivo] = useState("");
    const handleFileDomicilioArchivo = (file) => {
        setOpcionComprobanteDomicilioComprador(1);
        //setDomicilioArchivo(file);
        setDomicilioArchivoFoto(file);
    };
    //const [domicilioArchivoAval, setDomicilioArchivoAval] = useState("");
    const handleFileDomicilioArchivoAval = (file) => {
        setOpcionComprobanteDomicilioAval(1);
        //setDomicilioArchivoAval(file);
        setDomicilioArchivoFotoAval(file);
    };
    /**tomar fotos  */
    const webcamRef = useRef(null);
    const [fotoCredencial, setFotoCredencial] = useState(null);
    const [fotoCredencialAval, setsetFotoCredencialAval] = useState(null);
    const [domicilioArchivoFoto, setDomicilioArchivoFoto] = useState(null);
    const [domicilioArchivoFotoAval, setDomicilioArchivoFotoAval] = useState(null);
    const [domicilioFotoFoto, setDomicilioFotoFoto] = useState(null);
    const [domicilioFotoFotoAval, setDomicilioFotoFotoAval] = useState(null);
    /**abril modal que va a tomar la foto */
    const [showModal, setShowModal] = useState(false);
    const cerrarModal = () => setShowModal(false);
    const abrirModal = () => setShowModal(true);

    const [opcionFoto,setOpcionFoto] = useState(1);
    const openFoto  = (opcion) =>{
        console.log(opcion);
        setOpcionFoto(opcion);
        abrirModal();
    }
    const [alert, setAlert] = useState(false);
    const [msjError,setMsjError] = useState("");
    const [modalErrores, setModalErrores] = useState(false);
    const cerrarModalErrores = () => setModalErrores(false);
    const abrirModalErrores = () => setModalErrores(true);
    const [auth, setAuth] = useState(null);
    const [modalSucces,setModalSucces] = useState(false);
    const [msjExito,setMsjExito] = useState("");
    const abrirModalSucces = () => setModalSucces(true);
    let navigate = useNavigate();


    /*llenar el carrito*/
    useEffect( () => {
        const init = async() => {
            const user = await getDatosUsers();
            if(user === null){
                navigate("/login", { replace: true });
            }else{
                if (user.rol!=1 && user.rol!=2) {
                    navigate("/", { replace: true });
                } else {
                    if(user.rol == 2){
                        const rutaApi = await getRutaApi();
                        if (rutaApi == null) {
                            navigate("/rutas", { replace: true });
                        }else{
                            setRutaApi(rutaApi);
                            setOpcionruta(JSON.parse(rutaApi).Id_Ruta);
                        }
                        
                    }
                  /**obtener roles */
                  //setIsLoading(true);
                  /**obtener tmbien las rutas */
                  getRutas("",1);
                  getArticulosCarrito();
                  setAuth(user);
                }
            }
           
        }
       init();
       
    },[getArticulosCarrito,setReloadCar])
    useEffect(()=>{
        if(reloadCar){    
            setCar(null);
            getArticulosCarrito();
            setReloadCar(false);
           
        }        
    },[reloadCar]);
    const getRutas = useCallback( async(buscar,page_number) => {
        //console.log(buscar);
        //setBusqueda(buscar);
        const TOKEN = await getTokenApi();
        const formdata = {
            "busqueda": buscar,
            "token" : TOKEN,
            "pagina" : page_number,
            "opcTodos":"1"
          }
        //setCurrPage(page_number);
        var rutascompra = await getRutasCompraApi(formdata);
        //setTotalPaginas(rutascompra.totalPaginas); 
        setRutas(rutascompra.rutas);
        //setUsuarios(usariosR.usuarios); 
        //setIsLoading(false); 
      },[]);
    const getArticulosCarrito = useCallback(async() => {
        setProducts(null);
        const cart = await getProductCarApi();
        const TOKEN = await getTokenApi();
        const productTemp = [];
        let total = 0;
        for await(const product of cart){
            const formdata = {
                "token" : TOKEN,
                "idMueble" : product.idProduct,
            }
            let precio = 0;
            const response = await findByCatIdApi(formdata);
            const dataArticulo = JSON.parse(response.articulo);
            //console.log(response);
            dataArticulo.quantity = product.quantity;
            dataArticulo.opcionCompra = product.opcionCompra;
            switch (product.opcionCompra) {
                case "1":
                default:
                    precio = (product.quantity * parseFloat(dataArticulo.Precio_Mueble));
                    break;
                case "2":
                    precio = (product.quantity * parseFloat(dataArticulo.Precio3Meses_Mueble));
                break;
                case "3":
                    precio = (product.quantity * parseFloat(dataArticulo.Precio6Meses_Mueble));
                break;
                case "4":
                    precio = (product.quantity * parseFloat(dataArticulo.PrecioContado_Mueble));
                break;     
            }
            total = total + precio;
            dataArticulo.total = precio;
            productTemp.push(dataArticulo); 
            
        }
        //console.log(Number.parseFloat(total).toFixed(2));
        setProducts(productTemp);
        setpreciofinal(Number.parseFloat(total).toFixed(2));
        //console.log(productTemp);
        
        if (productTemp.length != 0 && productTemp !=null) {
            setDisabledButton(false);
        }else{
            setDisabledButton(true);
        }
    },);

    const clearAval = () => {
        console.log(imagenAval);
        if (sigCanvasAval.current != null) {
            sigCanvasAval.current.clear();
        }
        setImagenAval("");
    }
    const clearCliente = () => {
        console.log(imagenCliente);
        if (sigCanvasCliente.current != null) {
            sigCanvasCliente.current.clear();
        }
        setImagenCliente("");
    }

    const saveAval = () => {
        setImagenAval(sigCanvasAval.current.getTrimmedCanvas().toDataURL("image/png"));
    }
    const saveCliente = () => {
        setImagenCliente(sigCanvasCliente.current.getTrimmedCanvas().toDataURL("image/png"));
    }
    const onUserMedia = (e) => {
        console.log(e);
    }
    const capturePhoto = useCallback(async (opc) => {
        console.log(opc);
        const imageSrc = webcamRef.current.getScreenshot();
        
        
        switch(opc){
            case 1:
            default:
                setOpcionCrecencialComprador(2);
                setFotoCredencial(imageSrc);
                break;
            case 4:
                setOpcionCredencialAval(2);
                setsetFotoCredencialAval(imageSrc);
                break;
            case 2:
                setOpcionComprobanteDomicilioComprador(2);
                setDomicilioArchivoFoto(imageSrc);
                break;
            case 5:
                setOpcionComprobanteDomicilioAval(2);
                setDomicilioArchivoFotoAval(imageSrc);
                break;
            case 3:
                setOpcionFotoDomicilioComprador(2);
                setDomicilioFotoFoto(imageSrc);
                break;
            case 6:
                setOpcionFotoDomicilioAval(2);
                setDomicilioFotoFotoAval(imageSrc);
                break;
        }
       
        console.log(imageSrc);
        
        cerrarModal();
    }, [webcamRef]);
    const calcularSaldo = (anticipoEvent) =>{
        const regex = /^[0-9]*$/;
        if (regex.test(anticipoEvent)) {
            setAnticipo(anticipoEvent);
            setSaldoPendiente(precio-anticipoEvent);
        }else{
            /**lanzar excepcion */
            setAnticipo("");
        }
    }
    var multipleSelectDias = (e) =>{
        //setDiasValor(Array.isArray(e)?e.map(x=>x.value):[]);
        setDiasValor(e);
    }

    const enviarContrato = async() =>{
        setIsLoading(true);
        const regex = /^[0-9]*$/;
        /**empezar a validar el formulairo */
        var errores = "";
        var error = 0;
        if( nombreCliente.toString() == "" ) { error = 1; errores += "Debes proporcionar el nombre del(a) Comprador(a)\n"}
        if( direccionCliente.toString() == "" ) { error = 1; errores += "Debes proporcionar la dirección del(a) Comprador(a)\n"}
        if( colPobCliente.toString() == "" ) { error = 1; errores += "Debes proporcionar la colonia del(a) Comprador(a)\n"}
        if( celCliente.toString() == "" || regex.test(celCliente) == false ) { error = 1; errores += "Debes proporcionar el celular del(a) Comprador(a) y debe de ser un numero\n"}
        if( nombreAval.toString() == "" ) { error = 1; errores += "Debes proporcionar el nombre del(a) Aval\n"}
        if( direccionAval.toString() == "" ) { error = 1; errores += "Debes proporcionar la dirección del(a) Aval\n"}
        if( colPobAval.toString() == "" ) { error = 1; errores += "Debes proporcionar la colonia del(a) Aval\n"}
        if( celAval.toString() == "" || regex.test(celAval) == false) { error = 1; errores += "Debes proporcionar el celular del(a) Aval y debe de ser un numero\n"}
        if( anticipo.toString() == "" || regex.test(anticipo) == false) { error = 1; errores += "Debes proporcionar el anticipo del(a) Comprador(a) y debe de ser un numero\n"}
        if( pagoSemanal.toString() == "" || regex.test(pagoSemanal) == false) { error = 1; errores += "Debes proporcionar el pago semanal del(a) Comprador(a) y debe de ser un numero\n"}
        if(Array.isArray(dias) && dias.length== 0){
            errores+="Debes de seleccionar al menos un dia en el que pasaran a cobrar\n";
            error=1;
        }else{
            if(dias == null){
                errores+="Debes de seleccionar al menos un dia en el que pasaran a cobrar\n";
                error=1;
            }
        }
        if( fechaInicioPago.toString() == "" ) { error = 1; errores += "Debes proporcionar la fecha en que se iniciara el primer pago\n"}
        if( nombreLugar.toString() == "" ) { error = 1; errores += "Debes proporcionar el nombre del lugar del(a) Comprador(a)\n"}
        if( direccionLugar.toString() == "" ) { error = 1; errores += "Debes proporcionar la dirección de lugar del(a) Comprador(a)\n"}
        if( coloniaLugar.toString() == "" ) { error = 1; errores += "Debes proporcionar la colonia de lugar del(a) Comprador(a)\n"}
        if( celLugar.toString() == "" || regex.test(celLugar) == false ) { error = 1; errores += "Debes proporcionar el celular del lugar del(a) Comprador(a) y debe de ser un numero\n"}
        if( referencia1Nombre.toString() == "" ) { error = 1; errores += "Debes proporcionar el nombre de la referencia del(a) Comprador(a)\n"}
        if( referencia1Direccion.toString() == "" ) { error = 1; errores += "Debes proporcionar la dirección de la referencia del(a) Comprador(a)\n"}
        if( referencia1Colonia.toString() == "" ) { error = 1; errores += "Debes proporcionar la colonia de la referencia del(a) Comprador(a)\n"}
        if( referencia1Celular.toString() == "" || regex.test(referencia1Celular) == false) { error = 1; errores += "Debes proporcionar el celular de la referencia del(a) Comprador(a)\n"}
        /**validar archivos */
        if(opcionCredencialComprador == 0) {error = 1; errores += "Debes cargar la foto o archivo de la credencial o ine del(a) Comprador(a)\n" }
        if(opcionComprobanteDomicilioComprador == 0) {error = 1; errores += "Debes cargar la foto o archivo del comprobante de domicilio del(a) Comprador(a)\n" }
        if(opcionFotoDomicilioComprador == 0) {error = 1; errores += "Debes cargar la foto o archivo donde se vea el domicilio del(a) Comprador(a)\n" }
        if(opcionCredencialAval == 0) {error = 1; errores += "Debes cargar la foto o archivo de la credencial o ine del(a) Aval\n" }
        if(opcionComprobanteDomicilioAval == 0) {error = 1; errores += "Debes cargar la foto o archivo del comprobante de domicilio del(a) Aval\n" }
        if(opcionFotoDomicilioAval == 0) {error = 1; errores += "Debes cargar la foto o archivo donde se vea el domicilio del(a) Aval\n" }
        /**validar las firmas */
        if(imagenAval.toString() == "" || imagenAval == null) {error = 1; errores+="El aval debe firmar en el área que le corresponde\n";}
        if(imagenCliente.toString() == "" || imagenCliente == null) {error = 1; errores+="El Comprador(a) debe firmar en el área que le corresponde\n";}
        if (ruta.toString() == "" || ruta.toString()=="0") {
            error = 1; errores+="Debes de seleccionar una ruta\n"
        }
        
        
        console.log(error);
        console.log(errores);
        if(error != 1){
            /**enviar formulario */
            const TOKEN = await getTokenApi();
            const f = new FormData();
            const producto = {
                "nombreCliente": nombreCliente,
                "direccionCliente": direccionCliente,
                "colPobCliente": colPobCliente,
                "celCliente":celCliente,
                "nombreAval": nombreAval,
                "direccionAval": direccionAval,
                "colPobAval": colPobAval,
                "celAval": celAval,
                "anticipo": anticipo,
                "pagoSemanal": pagoSemanal,
                "fechaInicioPago":fechaInicioPago,
                "dias":dias,
                "nombreLugar": nombreLugar,
                "direccionLugar": direccionLugar,
                "coloniaLugar": coloniaLugar,
                "celLugar":celLugar,
                "referencia1Nombre": referencia1Nombre,
                "referencia1Direccion": referencia1Direccion,
                "referencia1Colonia": referencia1Colonia,
                "referencia1Celular": referencia1Celular,
                "referencia2Nombre": referencia2Nombre,
                "referencia2Direccion": referencia2Direccion,
                "referencia2Colonia": referencia2Colonia,
                "referencia2Celular": referencia2Celular,
                "firmaComprador": imagenCliente,
                "firmaAval": imagenAval,
                "productos":products,
                "opcionCredencialComprador":opcionCredencialComprador,
                "opcionComprobanteDomicilioComprador":opcionComprobanteDomicilioComprador,
                "opcionFotoDomicilioComprador":opcionFotoDomicilioComprador,
                "opcionCredencialAval":opcionCredencialAval,
                "opcionComprobanteDomicilioAval":opcionComprobanteDomicilioAval,
                "opcionFotoDomicilioAval":opcionFotoDomicilioAval,
                "precioTotal":precio,
                "saldoPendiente":saldoPendiente,
                "credencialComprador":"",
                "comprobanteDomicilioComprador":"",
                "fotoDomicilioComprador":"",
                "fotoCredencialAval":"",
                "comprobanteDomicilioAval":"",
                "fotoDomicilioAval":"",
                "ruta":ruta,
                //"dias":dias,
                //"opcion" : (idArticulo == 0) ? 1:2 ,
                "token" :TOKEN,
            };
            
            //credencialcomprador
            if (opcionCredencialComprador == 1) {
                for (let index = 0; index < fotoCredencial.length; index++) {
                    f.append("credencialComprador",fotoCredencial[index]);
                } 
            }else{
                //f.append("credencialComprador",fotoCredencial);
                producto.credencialComprador = fotoCredencial;
            }
            //domiciliocomprador
            if (opcionComprobanteDomicilioComprador == 1) {
                for (let index = 0; index < domicilioArchivoFoto.length; index++) {
                    f.append("comprobanteDomicilioComprador",domicilioArchivoFoto[index]);
                } 
            }else{
                producto.comprobanteDomicilioComprador = domicilioArchivoFoto;
            }
            //fotodomiciliocomprador
            if (opcionFotoDomicilioComprador == 1) {
                for (let index = 0; index < fotoCredencial.length; index++) {
                    f.append("fotoDomicilioComprador",domicilioFotoFoto[index]);
                } 
            }else{    
                producto.fotoDomicilioComprador = domicilioFotoFoto;
            }
            //credenciaaval
            if (opcionCredencialAval == 1) {
                for (let index = 0; index < fotoCredencialAval.length; index++) {
                    f.append("fotoCredencialAval",fotoCredencialAval[index]);
                } 
            }else{
                producto.fotoCredencialAval = fotoCredencialAval;
            }
            //domicilioavalcomprobanteaval
            if (opcionComprobanteDomicilioAval == 1) {
                for (let index = 0; index < domicilioArchivoFoto.length; index++) {
                    f.append("comprobanteDomicilioAval",domicilioArchivoFotoAval[index]);
                } 
            }else{
                producto.comprobanteDomicilioAval = domicilioArchivoFotoAval;
            }
            //fotodomicilioaval
            if (opcionFotoDomicilioAval == 1) {
                for (let index = 0; index < fotoCredencial.length; index++) {
                    f.append("fotoDomicilioAval",domicilioFotoFotoAval[index]);
                } 
            }else{
                producto.fotoDomicilioAval = domicilioFotoFotoAval;
            }
            f.append("contrato",JSON.stringify(producto));
            console.log(f);
            const response = await saveContratoApi(f);
            if (response != null) {
                if (response.succes == "succes" && response.hasOwnProperty('succes')===true && response!= null) {
                    setMsjExito(response.message);
                    abrirModalSucces();
                    deleteCartApi();
                
                } else {
                    setAlert(true);
                    setMsjError(response.message);
                    abrirModalErrores();
                    setIsLoading(false);
                }
            }else{
                setAlert(true);
                setMsjError("algo ocurrio");
                abrirModalErrores();
                setIsLoading(false);
            }
            
        }else{
            setAlert(true);
            setMsjError(errores);
            abrirModalErrores();
            setIsLoading(false);
        }
        
        setIsLoading(false);
    }
    const cerrarModalExito = () =>{
        window.location.href = "/articulos";
    }
  return (
    <>
    <div className="contenido">
        <div className='contenidoSub'>
            
                <div className='cuadroContrato'>
                    <div className="containerLogin">
                        <div className="filtros_pag">
                        {
                                    isLoading ? (
                                        <LoadingSpinner
                                        showSpinner={isLoading}
                                        />
                                        ):
                                    (
                                    <>
                                    </>
                                    )
                        }

                        </div>
                        <Form>
                            <Container>
                                <Alert variant="warning">
                                    <Alert.Heading>Advertencia</Alert.Heading>
                                    <p>
                                     Antes de seguir con el contrato revisar si el comprador ya acabo de pagar su anterior contrato o todos los contratos que tenga &nbsp; 
                                     <a href={`/contratos`} title="Contratos" className=" btn btn-primary botonIcono botonNuevo showmessages">Ir a Contratos</a>
                                     </p>

                                </Alert>
                            
                            <h3>Contrato</h3>
                            {
                                auth ? (
                                    auth.rol == 1 && (
                                        <>
                                        <Row>
                                            <Col md={12} className='justificarTexto'>
                                                <Form.Group className='textoEnd'>
                                                    <Form.Control defaultValue={ruta} placeholder="Selecciona la ruta" onChange={event => setOpcionruta(event.target.value)} as="select">
                                                        <option value="0">Selecciona una ruta</option>
                                                        {
                                                            rutas != null &&(
                                                                map(rutas,(ruta,index)=>(
                                                                    <option value={`${ruta.Id_Ruta}`}>{ruta.Nom_Ruta}</option>
                                                                ))
                                                            )
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        </>
                                    )
                                ):(<></>)
                            }
                            
                            <Clausulas/>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>SR.(A)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="cliente" defaultValue={nombreCliente} onChange={event => setNombreCliente(event.target.value)} name='cliente' type="text" placeholder="SR.(A)" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Dirección</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="direccion" defaultValue={direccionCliente} onChange={event => setDireccionCliente(event.target.value)} name='direccion' type="text" placeholder="Dirección" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Col/Poblacion</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="colonia" defaultValue={colPobCliente} onChange={event => setColPobCliente(event.target.value)} name='colonia' type="text" placeholder="Col/Poblacion" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Tel:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="telefono" defaultValue={celCliente} onChange={event => setCelCliente(event.target.value)} name='telefono' type="number" min="0" max="9999999999" placeholder="Tel:" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Nombre Aval</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="nomAval" defaultValue={nombreAval} onChange={event => setNombreAval(event.target.value)} name='nomAval' type="text" placeholder="Nombre Aval" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Dirección</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="coloniaAval" defaultValue={direccionAval} onChange={event => setDireccionAval(event.target.value)} name='coloniaAval' type="text" placeholder="Dirección" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Col/Poblacion</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="coloniaAval" defaultValue={colPobAval} onChange={event => setColPobAval(event.target.value)} name='coloniaAval' type="text" placeholder="Col/Poblacion" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Tel:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="telefonoAval" defaultValue={celAval} onChange={event => setCelAval(event.target.value)} type="number" min="0" max="9999999999" name='telefonoAval' placeholder="Tel:" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group>
                                    <h4>Articulos</h4>
                                </Form.Group>
                            </Row>
                            
                            <div className="prductos-list">
                                <Row className="row-modificada">
                                    {
                                        map(products,(product) =>(
                                            <Col key={product.Id_Mueble} xs={12} md={10} lg={8}>
                                            <Product key={product.Id_Mueble} product={product} setReloadCar={setReloadCar}></Product>
                                            </Col>                    
                                        ))
                                    }
                                </Row>
                            </div>
                            <Row>
                                <Col md={9}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label><b>Importe total de la venta es de  ${precio} </b></Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                {/*
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Control defaultValue={precio} id="importe" name='importe' type="text" placeholder="Importe" />
                                    </Form.Group>
                                </Col>
                                */}
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Anticipo $</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Control id="anticipo" defaultValue={anticipo} onChange={event => calcularSaldo(event.target.value)} name='anticipo' type="num" placeholder="Anticipo" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Saldo Pendiente $</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>{saldoPendiente}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>Pagos semanales de $</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Control id="pagoSemanal" defaultValue={pagoSemanal} onChange={event => setPagoSemanal(event.target.value)} name='pagoSemanal' type="num" min="0" placeholder="Pago Semanal" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                
                                <Col md={3}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>A partir del</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Control id="inicioPago" defaultValue={fechaInicioPago} onChange={event => setFechaInicioPago(event.target.value)} name='inicioPago' type="date" placeholder="Fecha" />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Dias de Cobro</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Select
                                        placeholder='Selecciona los días'
                                        options={diasOptions}
                                        styles={customStyles}
                                        isMulti
                                        defaultValue={dias}
                                        id="dias"
                                        onChange={multipleSelectDias}             
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                              
                            {
                                    /*
                              <br></br>
                            <Row>
                                
                                {
                                    /*
                                    <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Cobrador</Form.Label>
                                    </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Control id="Cobrador" name='Cobrador' type="text" placeholder="Cobrador" />
                                        </Form.Group>
                                    </Col>
                                    
                                
                                
                            </Row>
                            */
                                }
                            <h3>Referencia Laboral</h3>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Nombre de Lugar</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="nombreLugar" defaultValue={nombreLugar} onChange={event=>setNombreLugar(event.target.value)} name='nombreLugar' type="text" placeholder="Nombre de Lugar" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Dirección:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="direccionLugar" defaultValue={direccionLugar} onChange={event=>setDireccionLugar(event.target.value)} name='direccionLugar' type="text" placeholder="Dirección" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Colonia:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="lugarColonia" defaultValue={coloniaLugar} onChange={event=>setColoniaLugar(event.target.value)} name='lugarColonia' type="text" placeholder="Colonia" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Celular:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia1Celular" min="0" defaultValue={celLugar} onChange={event=>setCelLugar(event.target.value)} name='lugarCelular' type="number" placeholder="Celular" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h3>Referencia 1</h3>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>SR.(A)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia1" defaultValue={referencia1Nombre} onChange={event=>setReferencia1Nombre(event.target.value)} name='referencia1' type="text" placeholder="SR.(A)" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Dirección:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia1Direccion" defaultValue={referencia1Direccion} onChange={event=>setReferencia1Direccion(event.target.value)} name='referencia1Direccion' type="text" placeholder="Dirección" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Colonia:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia1Colonia" defaultValue={referencia1Colonia} onChange={event=>setReferencia1Colonia(event.target.value)} name='referencia1Colonia' type="text" placeholder="Colonia" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Celular:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia1Celular" defaultValue={referencia1Celular} onChange={event=>setReferencia1Celular(event.target.value)} name='referencia1Celular' type="number" min="0" placeholder="Celular" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h3>Referencia 2</h3>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>SR.(A)</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia2" defaultValue={referencia2Nombre} onChange={event=>setReferencia2Nombre(event.target.value)} name='referencia2' type="text" placeholder="SR.(A)" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Dirección:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia2Direccion" defaultValue={referencia2Direccion} onChange={event=>setReferencia2Direccion(event.target.value)} name='referencia2Direccion' type="text" placeholder="Dirección" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Colonia:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia2Colonia" defaultValue={referencia2Colonia} onChange={event=>setReferencia2Colonia(event.target.value)} name='referencia2Colonia' type="text" placeholder="Colonia" />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className='textoEnd'>
                                        <Form.Label>Celular:</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Control id="referencia2Celular" defaultValue={referencia2Celular} onChange={event=>setReferencia2Celular(event.target.value)} name='referencia2Celular' type="number" min="0" placeholder="Celular" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th className="borderTabla">Documento</Th>
                                            <Th className="borderTabla">Especificaciones</Th>
                                            <Th className="borderTabla">Adjuntar</Th>
                                        </Tr>
                                        </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td className="borderTabla">
                                                Identificación Oficial del comprador
                                            </Td>
                                            <Td className="borderTabla">
                                                Identificación Oficial del comprador
                                            </Td>
                                            <Td className="borderTabla">
                                                <FileUploader handleFile={handleFileCredencial} />
                                                &#160;
                                                <Button onClick={() => openFoto(1)}>
                                                    Tomar Foto
                                                </Button>
                                                <Form.Group>
                                                {
                                                    opcionCredencialComprador != 0 && opcionCredencialComprador == 1 ?
                                                    <p>Subir Archivo: {fotoCredencial[0].name}</p>
                                                    : 
                                                        opcionCredencialComprador == 2 ?
                                                            <img  className="camaracssFoto" src={fotoCredencial} alt="Screenshot"></img>
                                                        :
                                                        null
                                                }
                                                </Form.Group>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="borderTabla">
                                                Comprobante de Domicilio del Comprador
                                            </Td>
                                            <Td className="borderTabla">
                                                Comprobante de Domicilio del comprador
                                            </Td>
                                            <Td className="borderTabla">
                                                <FileUploader handleFile={handleFileDomicilioArchivo} />
                                                    &#160;
                                                <Button onClick={() => openFoto(2)}>
                                                    Tomar Foto
                                                </Button>
                                                <Form.Group>
                                                {
                                                    opcionComprobanteDomicilioComprador != 0 && opcionComprobanteDomicilioComprador == 1 ?
                                                    <p>Subir Archivo: {domicilioArchivoFoto[0].name}</p>
                                                    : 
                                                        opcionComprobanteDomicilioComprador == 2 ?
                                                            <img  className="camaracssFoto" src={domicilioArchivoFoto} alt="Screenshot"></img>
                                                        :
                                                        null
                                                }
                                                </Form.Group>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="borderTabla">
                                                Foto de Domicilio del Comprador
                                            </Td>
                                            <Td className="borderTabla">
                                                Foto de Domicilio del Comprador
                                            </Td>
                                            <Td className="borderTabla">
                                                <FileUploader handleFile={handleFileDomicilioFoto} />
                                                    &#160;
                                                <Button onClick={() => openFoto(3)}>
                                                    Tomar Foto
                                                </Button>
                                                <Form.Group>
                                                {
                                                    opcionFotoDomicilioComprador != 0 && opcionFotoDomicilioComprador == 1 ?
                                                    <p>Subir Archivo: {domicilioFotoFoto[0].name}</p>
                                                    : 
                                                        opcionFotoDomicilioComprador == 2 ?
                                                            <img  className="camaracssFoto" src={domicilioFotoFoto} alt="Screenshot"></img>
                                                        :
                                                        null
                                                }
                                                </Form.Group>
                                            </Td>
                                        </Tr>
                                        <Tr className="fondoTabla">
                                            <Td className="borderTabla">
                                                Identificación Oficial Aval
                                            </Td>
                                            <Td className="borderTabla">
                                                Identificación Oficial del Aval
                                            </Td>
                                            <Td className="borderTabla">
                                                <FileUploader handleFile={handleFileCredencialAval} />
                                                    &#160;
                                                <Button onClick={() => openFoto(4)}>
                                                    Tomar Foto
                                                </Button>
                                                <Form.Group>
                                                {
                                                    opcionCredencialAval != 0 && opcionCredencialAval == 1 ?
                                                    <p>Subir Archivo: {fotoCredencialAval[0].name}</p>
                                                    : 
                                                    opcionCredencialAval == 2 ?
                                                            <img  className="camaracssFoto" src={fotoCredencialAval} alt="Screenshot"></img>
                                                        :
                                                        null
                                                }
                                                </Form.Group>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="borderTabla">
                                                Comprobante de Domicilio del Aval
                                            </Td>
                                            <Td className="borderTabla">
                                                Comprobante de Domicilio del Aval
                                            </Td>
                                            <Td className="borderTabla">
                                                <FileUploader handleFile={handleFileDomicilioArchivoAval} />
                                                    &#160;
                                                <Button onClick={() => openFoto(5)}>
                                                    Tomar Foto
                                                </Button>
                                                <Form.Group>
                                                {
                                                    opcionComprobanteDomicilioAval!= 0 && opcionComprobanteDomicilioAval == 1 ?
                                                    <p>Subir Archivo: {domicilioArchivoFotoAval[0].name}</p>
                                                    : 
                                                    opcionComprobanteDomicilioAval == 2 ?
                                                            <img  className="camaracssFoto" src={domicilioArchivoFotoAval} alt="Screenshot"></img>
                                                        :
                                                        null
                                                }
                                                </Form.Group>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="borderTabla">
                                                Foto de Domicilio del Aval
                                            </Td>
                                            <Td className="borderTabla">
                                                Foto de Domicilio del Aval
                                            </Td>
                                            <Td className="borderTabla">
                                                <FileUploader handleFile={handleFileDomicilioFotoAval} />
                                                   &#160;
                                                <Button onClick={() => openFoto(6)}>
                                                    Tomar Foto
                                                </Button>
                                               <Form.Group>
                                                {
                                                    opcionFotoDomicilioAval!= 0 && opcionFotoDomicilioAval == 1 ?
                                                    <p>Subir Archivo: {domicilioFotoFotoAval[0].name}</p>
                                                    : 
                                                    opcionFotoDomicilioAval == 2 ?
                                                            <img  className="camaracssFoto" src={domicilioFotoFotoAval} alt="Screenshot"></img>
                                                        :
                                                        null
                                                }
                                                </Form.Group>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Row>
                            <br></br>
                            <Pagare
                                fecha={new Date().toLocaleString() + ""}
                                colPobCliente={colPobCliente}
                                precio={precio}
                                nombreCliente={nombreCliente}
                                direccionCliente={direccionCliente}
                                celCliente={celCliente}
                            />
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Firma Aval</Form.Label>
                                    </Form.Group>
                                    {
                                        imagenAval != "" ? (
                                            <Form.Group>
                                                <img
                                                src={imagenAval}
                                                alt="my signature"
                                                style={{
                                                    display: "block",
                                                    margin: "0 auto",
                                                    //border: "1px solid black",
                                                    width: "150px",
                                                
                                                }}
                                                />
                                            </Form.Group>
                                        ):(
                                            <SignatureCanvas 
                                            ref={sigCanvasAval}
                                            canvasProps={{
                                                style:{
                                                    "border":"1px solid #000000",
                                                    "width":"100%",
                                                    "height":"200px",
                                                }
                                            }}
                                            />   
                                        )
                                    }
                                    <Form.Group>
                                        <div className="configuracionBotones">
                                            <Button className='btn-primary' onClick={saveAval}>Firmar</Button>
                                            &nbsp;
                                            <Button className='btn-primary' onClick={clearAval}>Borrar Firma</Button>
                                        </div>
                                    </Form.Group>
                                       
                                </Col>
                                <Col md={6}>
                                    
                                    <Form.Group>
                                        <Form.Label>Firma Cliente</Form.Label>
                                    </Form.Group>
                                    {
                                        imagenCliente != "" ? (
                                            <Form.Group>
                                                <img
                                                src={imagenCliente}
                                                alt="my signature"
                                                style={{
                                                    display: "block",
                                                    margin: "0 auto",
                                                    //border: "1px solid black",
                                                    width: "150px",
                                                
                                                }}
                                                />
                                            </Form.Group>
                                        ):(
                                            <SignatureCanvas
                                            ref={sigCanvasCliente} 
                                            canvasProps={{
                                               
                                                style:{
                                                    "border":"1px solid #000000",
                                                    "width":"100%",
                                                    "height":"200px",
                                                }
                                              }}
                                            />  
                                        )
                                    }
                                    <Form.Group>
                                        <div className="configuracionBotones">
                                            <Button className='btn-primary' onClick={saveCliente}>Firmar</Button>
                                            &nbsp;
                                            <Button className='btn-primary' onClick={clearCliente}>Borrar Firma</Button>
                                        </div>
                                    </Form.Group>
                                       
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={12}>
                                    <Button disabled={disabledButton} onClick={enviarContrato} variant="primary" type="button">
                                        Enviar Contrato
                                    </Button>        
                                </Col>
                            </Row>
                            <br></br>
                            </Container>
                            
                        </Form>
                        <div className='alignDerecha'>

                        </div>
                    </div>
                </div>
            
        </div>
    </div>
    {/**modal para tomar la camara */}
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={cerrarModal} animation={false}>
        <Modal.Header closeButton>
            <Modal.Title id="tituloModal">Tomar Foto</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
            <Form.Group>
            <Webcam
                className="camaracss"
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/png"
                videoConstraints={videoConstraints}
                onUserMedia={onUserMedia}
                mirrored={true}
                screenshotQuality = {0.92}
            />   
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => capturePhoto(opcionFoto)} id="botonModal">
                Tomar Foto
            </Button>
            <Button variant="secondary" onClick={cerrarModal}>
                Cerrar
            </Button>
        </Modal.Footer>
    </Modal>
    {/**modal de errores */}
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalErrores} onHide={cerrarModalErrores} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title id="tituloModal">Errores Encontrados</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <>
            {
              (alert == true) ?
                <Container>
                  <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                    <Alert.Heading>Advertencia</Alert.Heading>
                    <p>
                      {msjError}
                    </p>
                  </Alert>
              </Container>
              :(<></>)
            }
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={cerrarModalErrores} id="botonModal">
              Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      {/**modal de exito */}
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalSucces} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title id="tituloModal">Exito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
                <Container>
                  <Alert variant="success">
                    <Alert.Heading>Exito</Alert.Heading>
                    <p>
                      {msjExito}
                    </p>
                  </Alert>
              </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={cerrarModalExito} id="botonModal">
              Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
}

export default Contrato;
import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert, FormLabel} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import {
    useNavigate,useParams
  } from "react-router-dom";
import { getDatosUsers } from '../../api/Login';
import { replace } from 'formik';
import { getTokenApi } from '../../api/Token';
import { agregarPagoApi, getEstatusContratoPagoApi, getPagosByIdVentaApi } from '../../api/Contrato';


function Pagos(){
    const [auth, setAuth] = useState(null);

    let params = useParams();
    let navigate = useNavigate();
    let timer = 3000;
    let delay = 3000;
    let prevent = false;
    const [isLoading, setIsLoading] = useState(false);
    const [pagosArray,setPagosArray] = useState(null);
    const [contratoDatos,setContratoDatos] = useState(null);
    const [idContrato,setIdContrato] = useState(0);

    /**para hacer pagos */
    const[sumaNormal,setSumaNormal] = useState(0);
    const[suma3Meses,setSuma3Meses] = useState(0);
    const[suma6Meses,setSuma6Meses] = useState(0);
    const[sumaContado,setSumaContado] = useState(0);
    const[sumaPorComoCompro,setSumaPorComoCompro] = useState(0);
    const[mostrarContado,setMostrarContado] = useState(0);
    const[mostrar3Meses,setMostrar3Meses] = useState(0);
    const[mostrar6Meses,setMostrar6Meses] = useState(0);
    const[llevaPagado,setLlevaPagado] = useState(0);
    const[anticipo,setAnticipo] = useState(0);
    const[totalImporte,setTotalImporte] = useState(0);
    const[pagoSemanal,setPagoSemanal] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showModal, setShowModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [msjError,setMsjError] = useState("");
    const [pagoDeposito,setPagoDeposito] = useState(0);

    var agregarPago = async () =>{
        const TOKEN = await getTokenApi();
        const formdata = {
            "token" : TOKEN,
            "idContrado" : idContrato
          }
        /**obtener cuanto debe  */
        const pagoActual = await getEstatusContratoPagoApi(formdata);
        setSumaNormal(pagoActual.sumaNormal);
        setSuma3Meses(pagoActual.suma3Meses);
        setSuma6Meses(pagoActual.suma6Meses);
        setSumaContado(pagoActual.sumaContado);
        setSumaPorComoCompro(pagoActual.sumaPorComoCompro);
        setMostrar3Meses(pagoActual.mostrar3Meses);
        setMostrarContado(pagoActual.mostrarContado);
        setMostrar6Meses(pagoActual.mostrar6Meses);
        setLlevaPagado(pagoActual.sumaPagos);
        setAnticipo(pagoActual.anticipo);
        setTotalImporte(pagoActual.importe);
        setPagoSemanal(pagoActual.abonoSemanal);
        setIdContrato(idContrato);
        setPagoDeposito(0);
        handleShow();
    }
    var updatePago = async() => {
        setIsLoading(true);
        const regex = /^[0-9]*$/;
        var errores = "";
        var error = 0;
        if(pagoDeposito.toString() == "" || regex.test(pagoDeposito) == false) { error = 1; errores += "Debes proporcionar el pago y debe de ser un numero\n"}
        if(idContrato.toString() == "" || regex.test(idContrato) == false || idContrato.toString() == 0){ error = 1; errores += "Debes de seleccionar un contrato para agregar su pago correspondiente\n"}
        if (error != 1) {
            const TOKEN = await getTokenApi();
            const formdata = {
                "idContrado":idContrato,
                "token" : TOKEN,
                "pago" : pagoDeposito
            }
            const response = await agregarPagoApi(formdata);
            if (response.succes === "succes") {
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    });
                    handleClose();
                    getAllPagos();
                    setAlert(false);
            } else {
                setAlert(true);
                setMsjError(response.message);
            }
        }else{
            setAlert(true);
            setMsjError(errores);
            
        }
        setIsLoading(false);
    }
    const getAllPagos = useCallback( async() => {
        const TOKEN = await getTokenApi();
        const formdata={
            "token" : TOKEN,
            "idContrado" : params.pagoId,
        }
        const pagos = await getPagosByIdVentaApi(formdata);
        console.log(pagos);
        setContratoDatos(pagos.contrato);
        setPagosArray(pagos.pagos);
      },[]);
    useEffect(
        () => {
            const init = async()=>{
                const user = await getDatosUsers();
                console.log(user);
                
                if (user === null) {
                    navigate("/",{replace:true});
                    setAuth(null)
                }else{
                    /**llamar al api para obtener todos los pagos */
                    if (params.pagoId != null) {
                        setIdContrato(params.pagoId);
                        getAllPagos();
                        
                    } else {
                        navigate("/",{replace:true});
                    }
                    setAuth(user);
                }
            }
            init();
        },[]
    );
    return(
        <>
        <div className="contenido">
            <div className='contenidoSub'>
                <div className='cuadroContrato'>
                    <div className="containerLogin">
                        <Container>
                            <div className='filtros_pag'>
                                {
                                    auth ? (
                                        auth.rol != 0 ?(
                                            <Button title='Agregar Pago' onClick={() => agregarPago()}  className='botonIcono botonNuevo showmessages' type='button' name='button'>Agregar Pago</Button>
                                        ):(<></>)
                                    ):(
                                        <></>
                                    )
                                }
                            </div>
                        </Container>
                        {
                            isLoading ? (<LoadingSpinner  showSpinner={isLoading} />):(<></>)
                        }
                        {
                            contratoDatos != null &&(
                                <>
                                <Container>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label>
                                                Folio de contrato {contratoDatos[0].Id_Venta}
                                            </Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>
                                                Contrato de {contratoDatos[0].Comprador_Venta}
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Label>
                                                Pagos
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th className="borderTabla">Pago no.</Th>
                                                <Th className="borderTabla">Cobrador</Th>
                                                <Th className="borderTabla">Abono</Th>
                                                <Th className="borderTabla">Fecha</Th>
                                            </Tr>
                                            
                                        </Thead>
                                        <Tbody>
                                                {
                                                    pagosArray != null &&(
                                                        map(pagosArray,(p,index)=>(
                                                            <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                                                <Td className="borderTabla">{p.Num_Pago}</Td>
                                                                <Td className="borderTabla">{p.Nom_Usuario+" "+p.Apaterno_Usuario+" "+p.Amaterno_Usuario}</Td>
                                                                <Td className="borderTabla">{p.Cantidad_Pago}</Td>
                                                                <Td className="borderTabla">{p.Fecha_Pago}</Td>
                    
                                                            </Tr>
                                                        ))
                                                    )
                                                }
                                            </Tbody>
                                    </Table>
                                    &nbsp;						
                                    <div className="alignDerecha">
                                        {
                                        pagosArray != null ?
                                        ( 
                                            <></>
                                        ):
                                        (
                                        <p>No hay registros</p>
                                        )
                                        }
                                    </div>
                                </Container>
                                </>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>  
           {/**inicio modals */}
        {/**modal activar desactivar usuario */}
      <Modal  show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title id="tituloModal">Agregar Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <>
            {
              (alert == true) ?
                <Container>
                  <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                    <Alert.Heading>Advertencia</Alert.Heading>
                    <p>
                      {msjError}
                    </p>
                  </Alert>
              </Container>
              :(<></>)
            }
          </>
          <Form.Group>
                    <FormLabel>
                       Total importe es de ${totalImporte}, su anticipo fue de ${anticipo} y lleva pagado un total de ${llevaPagado}
                    </FormLabel>
          </Form.Group>
          <Form.Group>
                    <FormLabel>
                        Pago Semanal de ${pagoSemanal} 
                    </FormLabel>
          </Form.Group>
          <Form.Group>
                    <FormLabel>
                        Debe Actualmente ${sumaPorComoCompro} 
                    </FormLabel>
          </Form.Group>
          <Form.Group>
            {
                mostrarContado == 1 && (
                    <FormLabel>
                        Pago de contado ${sumaContado} si paga ahora
                    </FormLabel>
                )
            }
          </Form.Group>
          <Form.Group>
            {
                mostrar3Meses == 1 && (
                    <FormLabel>
                        Pago a 3 Meses ${suma3Meses} si paga ahora
                    </FormLabel>
                )
            }
          </Form.Group>
          <Form.Group>
            {
                mostrar6Meses == 1 && (
                    <FormLabel>
                        Pago a 6 Meses ${suma6Meses} si paga ahora
                    </FormLabel>
                )
            }
          </Form.Group>
          <Form.Group>
            <Form.Check
                type="switch"
                id="custom-switch"
                label="Liquidar deuda"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control placeholder="Pago"   
                id="pago"
                name="pago"
                type="number"
                defaultValue={pagoDeposito}
                onChange={event => setPagoDeposito(event.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updatePago} id="botonModal">
              Agregar Pago
          </Button>
          <Button variant="secondary" onClick={handleClose}>
              Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
        {/**fin modals */}  
        </>
    );
}
export default Pagos;
import { API_URL } from "../utils/Constant";
import { getTokenApi } from "./Token";
export async function getRolesApi(formdata){
    try {
        const url= `${API_URL}Usuarios/getRoles`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function saveUsuarioApi(formdata){
    try {
        const url= `${API_URL}Usuarios/saveUsuario`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getUsuariosApi(formdata){
    try {
        const url= `${API_URL}Usuarios/getAllUsuarios`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function activarUsuarioApi(formdata){
    try {
        const url= `${API_URL}Usuarios/activarDesactvarUsuario`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function removeSesionApi(formdata){
    try {
        const url= `${API_URL}Usuarios/removeSesion`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getPagosByIdUsuarioApi(formdata){
    try {
        const url= `${API_URL}Contrato/getPagosByIdUsuario`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

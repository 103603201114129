import { AsyncStorage } from 'AsyncStorage';
import {size,map,filter} from "lodash";
import { CART } from '../utils/Constant';
export async function getProductCarApi(){
    try{
        //await AsyncStorage.removeItem(CART);
       const cart = await AsyncStorage.getItem(CART);
       if(!cart){
          return [];
       }else{
         return JSON.parse(cart);
       }
    }catch(error){
        return null;
    }
}

export async function addProductCarApi(idProduct,quantity,opcionCompra){
    try {
        const cart = await getProductCarApi();
        if(!cart) throw "Error al obtener el carrito";

        if (size(cart) == 0) {
            cart.push({
                idProduct,
                quantity,
                opcionCompra
            })
        } else {
            /**buscar si el producto ya exist */
            let found = false;
            map(cart,(product) =>{
                if (product.idProduct == idProduct) {
                    product.quantity += quantity;
                    found = true;
                    return product;
                }
            });

            if(found == false){
                cart.push({
                    idProduct,
                    quantity,
                    opcionCompra
                });
            }
        }
        await AsyncStorage.setItem(CART,JSON.stringify(cart));
        return true;
    } catch (error) {
        return false
    }
}

export async function deleteProductCarApi(idProduct){
   try {
      
       const cart = await getProductCarApi();
       const newCart = filter( cart,(product) => {
           return product.idProduct != idProduct;
       });
       await AsyncStorage.setItem(CART,JSON.stringify(newCart));
       return true;
   } catch (error) {
       return null;
   }
}

export async function incrementarProductCarApi(idProduct){
   try {
        const cart = await getProductCarApi();
        map(cart , (product) => {
            if (product.idProduct == idProduct) {
               return (product.quantity += 1);   
            }
        })
        await AsyncStorage.setItem(CART,JSON.stringify(cart));
        return true;
   } catch (error) {
       return null
   }
}

export async function cambiarOpcionCompraApi(idProduct,opcionCompra) {
    try {
        const cart = await getProductCarApi();
        map(cart , (product) => {
            if (product.idProduct == idProduct) {
               return (product.opcionCompra = opcionCompra);   
            }
        })
        await AsyncStorage.setItem(CART,JSON.stringify(cart));
        return true;
   } catch (error) {
       return null
   }
}

export async function decrementarProductCarApi(idProduct){
    try {
        let isDelete = false;
         const cart = await getProductCarApi();
         map(cart , (product) => {
             if (product.idProduct == idProduct) {
                 if(product.quantity == 1){
                    isDelete = true;
                    return null; 
                 }else{
                    return (product.quantity -= 1); 
                 }
             }
         });
         if (isDelete) {
           await deleteProductCarApi(idProduct);  
         }else{
            await AsyncStorage.setItem(CART,JSON.stringify(cart));
         }  
         return true;
    } catch (error) {
        return null
    }
 }

 export async function deleteCartApi(){
    try {
        const token = await localStorage.removeItem('@AsyncStorage:'+CART);
        return true;
    } catch (error) {
        return null;
    }
 }

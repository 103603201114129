import React, {useContext,useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container,Alert, Row, Col, Form, FormLabel} from "react-bootstrap";
import { agregarPagoApi, cancelarContratoByIdApi, getAllContratosApi, getEstatusContratoPagoApi } from '../../api/Contrato';
import { getTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import { getRutaApi } from '../../api/RutaElegida';
function Contratos() {
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    const [busqueda,setBusqueda] = useState("");
    const [contratos,setContratos] = useState(null);
    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0);
    let navigate = useNavigate();

    /**para hacer pagos */
    const[sumaNormal,setSumaNormal] = useState(0);
    const[suma3Meses,setSuma3Meses] = useState(0);
    const[suma6Meses,setSuma6Meses] = useState(0);
    const[sumaContado,setSumaContado] = useState(0);
    const[sumaPorComoCompro,setSumaPorComoCompro] = useState(0);
    const[mostrarContado,setMostrarContado] = useState(0);
    const[mostrar3Meses,setMostrar3Meses] = useState(0);
    const[mostrar6Meses,setMostrar6Meses] = useState(0);
    const[llevaPagado,setLlevaPagado] = useState(0);
    const[anticipo,setAnticipo] = useState(0);
    const[totalImporte,setTotalImporte] = useState(0);
    const[pagoSemanal,setPagoSemanal] = useState(0);
    const[idContrato,setIdContrato] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showModal, setShowModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [msjError,setMsjError] = useState("");
    const [pagoDeposito,setPagoDeposito] = useState(0);

    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");
      /**modal para activar o desactivar un usuario */
    const cerrarModal = () => setShowModal(false);
    const abrirModal = () => setShowModal(true);
    const getAllContratos = useCallback( async(buscar,page_number) => {
        setBusqueda(buscar);
        var idRutaApi = "0";
        /**verificar id del usuario */
        console.log('auth');
        console.log(auth);
        const user = await getDatosUsers();
        console.log(user.rol);
        if (user.rol == 3) {
          const rutaApi = await getRutaApi();
          if (rutaApi == null) {
            navigate("/rutas", { replace: true });
          }else{
            idRutaApi = JSON.parse(rutaApi).Id_Ruta;
          }
          
        }
        const TOKEN = await getTokenApi();
        const formdata = {
            "busqueda": buscar,
            "token" : TOKEN,
            "pagina" : page_number,
            "idRutaApi" : idRutaApi,
          }
        setCurrPage(page_number);
        var contratosAll = await getAllContratosApi(formdata);
        //console.log(usuario.totalPaginas);
        setTotalPaginas(contratosAll.totalPaginas); 
        setContratos(contratosAll.contratos); 
        setIsLoading(false); 
      },[]);
      useEffect(() => {
        const init = async() =>{
        const user = await getDatosUsers();
        console.log(user);
        
        if(user === null){
            navigate("/login", { replace: true });
        }else{
            //if (user.rol ==1 || user.rol == 0 ) {
              
              setAuth(user);
              getAllContratos("",1);
            //} else {
              //  navigate("/", { replace: true });
                
           // }
        }
        }
        init();
    }, [getAllContratos]);
    function handlePageChange(page) {
            setIsLoading(true);
            setCurrPage(page);
            //console.log("controler normal",page);
            getAllContratos(busqueda,page);
            // ... do something with `page`
    }
    var setBusquedaFuncion = (valor) =>{
        getAllContratos(valor,currPage);
    }
    var agregarPago = async (c) =>{
        const TOKEN = await getTokenApi();
        const formdata = {
            "token" : TOKEN,
            "idContrado" : c.Id_Venta
          }
        /**obtener cuanto debe  */
        const pagoActual = await getEstatusContratoPagoApi(formdata);
        setSumaNormal(pagoActual.sumaNormal);
        setSuma3Meses(pagoActual.suma3Meses);
        setSuma6Meses(pagoActual.suma6Meses);
        setSumaContado(pagoActual.sumaContado);
        setSumaPorComoCompro(pagoActual.sumaPorComoCompro);
        setMostrar3Meses(pagoActual.mostrar3Meses);
        setMostrarContado(pagoActual.mostrarContado);
        setMostrar6Meses(pagoActual.mostrar6Meses);
        setLlevaPagado(pagoActual.sumaPagos);
        setAnticipo(pagoActual.anticipo);
        setTotalImporte(pagoActual.importe);
        setPagoSemanal(pagoActual.abonoSemanal);
        setIdContrato(c.Id_Venta);
        setPagoDeposito(0);
        handleShow();
    }
    var updatePago = async() => {
        setIsLoading(true);
        const regex = /^[0-9]*$/;
        var errores = "";
        var error = 0;
        if(pagoDeposito.toString() == "" || regex.test(pagoDeposito) == false) { error = 1; errores += "Debes proporcionar el pago y debe de ser un numero\n"}
        if(idContrato.toString() == "" || regex.test(idContrato) == false || idContrato.toString() == 0){ error = 1; errores += "Debes de seleccionar un contrato para agregar su pago correspondiente\n"}
        if (error != 1) {
            const TOKEN = await getTokenApi();
            const formdata = {
                "idContrado":idContrato,
                "token" : TOKEN,
                "pago" : pagoDeposito
            }
            const response = await agregarPagoApi(formdata);
            if (response.succes === "succes") {
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    });
                    handleClose();
                    getAllContratos(busqueda,currPage);
                    setAlert(false);
            } else {
                setAlert(true);
                setMsjError(response.message);
            }
        }else{
            setAlert(true);
            setMsjError(errores);
            
        }
        setIsLoading(false);
    }
    const[activoContrato,setActivoContrato] = useState(0);
    var cancelarContrato = (contrato) =>{
        setIdContrato(contrato.Id_Venta);
        setAlert(false);
        if (contrato.EstadoMueble_Venta == 1) {
            setActivoContrato(0);
          setTitulomodal("Cancelar Contrato");
          setBotonModal("Cancelar Contrato");
          setMensajeModal("¿Esta seguro que desea cancelar el contrato "+contrato.Id_Venta+" de "+contrato.Comprador_Venta+" ?");
        }else{
            setActivoContrato(1);
          setTitulomodal("Activar Contrato");
          setBotonModal("Activar Contrato");
          setMensajeModal("¿Esta seguro que desea volver a activar el contrato "+contrato.Id_Venta+" de "+contrato.Comprador_Venta+" ?");
         }
        abrirModal();
    }
    var activarDesactivarContrato = async() =>{
        setIsLoading(true);
        const TOKEN = await getTokenApi();
        const formdata = {
            "idContrado" : idContrato,
            "token" : TOKEN,
            "Estatus_Venta" : activoContrato,
        } 
        const response = await cancelarContratoByIdApi(formdata);
        if(response.succes === "succes"){
            toast.success(response.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
                setShowModal(false);
                getAllContratos(busqueda,1);
        }else{
          setAlert(true);
          setMsjError(response.message);
        }
        setIsLoading(false);
    }
    return (
    <>
      <div className="contenido">
            <div className='contenidoSub'>
                <div className='cuadroContrato'>
                    <div className="containerLogin">
                        <div className="filtros_pag">
                            <Form.Group>
                                <Form.Control placeholder="Busqueda por Nombre o Folio"   
                                    id="busquedaInput"
                                    name="busquedaInput"
                                    type="text"
                                    onChange={event => setBusquedaFuncion(event.target.value)}
                                />
                            </Form.Group>
                            &nbsp;&nbsp;
                           
                        </div>
                        {
                            isLoading ? (<LoadingSpinner  showSpinner={isLoading} />):(<></>)
                        }
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th className="borderTabla">Folio</Th>
                                    <Th className="borderTabla">Nombre Comprador</Th>
                                    <Th className="borderTabla">Dirección Comprador</Th>
                                    <Th className="borderTabla">Nombre Vendedor</Th>
                                    <Th className="borderTabla">Aval</Th>
                                    <Th className="borderTabla">Días de Cobro</Th>
                                    <Th className="borderTabla">Pago Semanal</Th>
                                    <Th className="borderTabla">Fecha de Compra</Th>
                                    <Th className="borderTabla">Fecha Limite de Pago</Th>
                                    <Th className="borderTabla">Configuración</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    contratos != null &&
                                    (
                                        map(
                                            contratos,(c,index)=>(
                                                <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                                    <Td className="borderTabla">{c.Id_Venta}</Td>
                                                    <Td className="borderTabla">{c.Comprador_Venta}</Td>
                                                    <Td className="borderTabla">{c.Direccion_Venta}</Td>
                                                    <Td className="borderTabla">{c.Nom_Usuario}</Td>
                                                    <Td className="borderTabla">{c.NombreAval_Venta}</Td> 
                                                    <Td className="borderTabla">
                                                        {
                                                            c.NumeroDiasCobro_Venta != null &&
                                                            map(JSON.parse(c.NumeroDiasCobro_Venta),(diasTrabajo)=>(
                                                            <label key={diasTrabajo.value}>{diasTrabajo.label} &nbsp;</label>
                                                            ))
                                                        }
                                                    </Td>
                                                    <Td className="borderTabla">${c.PagoSemanal_Venta}</Td>
                                                    <Td className="borderTabla">{c.FechaVenta_Venta}</Td>
                                                    <Td className="borderTabla">{c.FechaLimitePago_Venta}</Td>
                                                    <Td className="borderTabla">
                                                        <div className="configuracionBotones">
                                                            
                                                            <a title="Ver Contrato" href={`/verContrato/${c.Id_Venta}`} className='botonView transparente'></a>
                                                            <a title="Ver Pagos" href={`/pagos/${c.Id_Venta}`} className='botonPrecios transparente'></a>
                                                            {
                                                              auth.rol == 1 && (
                                                                <>
                                                                  <Button title='Agregar Pago' onClick={() => agregarPago(c)}  className='botonPrecio transparente' type='button' name='button'></Button>
                                                                  <Button onClick={() => cancelarContrato(c)} title={ c.EstadoMueble_Venta != 1 ? ('Activar'):('Cancelar') } className={ c.EstadoMueble_Venta != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>    
                                                                </>
                                                              )
                                                            }
                                                           {
                                                              auth.rol == 2 || auth.rol == 3 ?(
                                                                <Button title='Agregar Pago' onClick={() => agregarPago(c)}  className='botonPrecio transparente' type='button' name='button'></Button>
                                                              ):(<></>)
                                                            }
                                                        </div>
                                                    </Td>
                                                </Tr>
                                            )
                                        )
                                    )
                                }
                            </Tbody>
                        </Table>
                        &nbsp;						
                        <div className="alignDerecha">
                            {
                            contratos != null ?
                            ( 
                            <Pagination
                                total={totalPaginas}
                                current={currPage}
                                onPageChange={page => handlePageChange(page)}
                            />
                            ):
                            (
                            <p>No hay registros</p>
                            )
                            }
                        </div>
                        <ToastContainer
                            transition={Flip}
                            position="bottom-center"
                            limit={1}
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </div>
            </div>
        </div>
        {/**inicio modals */}
        {/**modal activar desactivar usuario */}
      <Modal  show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title id="tituloModal">Agregar Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <>
            {
              (alert == true) ?
                <Container>
                  <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                    <Alert.Heading>Advertencia</Alert.Heading>
                    <p>
                      {msjError}
                    </p>
                  </Alert>
              </Container>
              :(<></>)
            }
          </>
          <Form.Group>
                    <FormLabel>
                       Total importe es de ${totalImporte}, su anticipo fue de ${anticipo} y lleva pagado un total de ${llevaPagado}
                    </FormLabel>
          </Form.Group>
          <Form.Group>
                    <FormLabel>
                        Pago Semanal de ${pagoSemanal} 
                    </FormLabel>
          </Form.Group>
          <Form.Group>
                    <FormLabel>
                        Debe Actualmente ${sumaPorComoCompro} 
                    </FormLabel>
          </Form.Group>
          <Form.Group>
            {
                mostrarContado == 1 && (
                    <FormLabel>
                        Pago de contado ${sumaContado} si paga ahora
                    </FormLabel>
                )
            }
          </Form.Group>
          <Form.Group>
            {
                mostrar3Meses == 1 && (
                    <FormLabel>
                        Pago a 3 Meses ${suma3Meses} si paga ahora
                    </FormLabel>
                )
            }
          </Form.Group>
          <Form.Group>
            {
                mostrar6Meses == 1 && (
                    <FormLabel>
                        Pago a 6 Meses ${suma6Meses} si paga ahora
                    </FormLabel>
                )
            }
          </Form.Group>
          <Form.Group>
            <Form.Check
                type="switch"
                id="custom-switch"
                label="Liquidar deuda"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control placeholder="Pago"   
                id="pago"
                name="pago"
                type="number"
                defaultValue={pagoDeposito}
                onChange={event => setPagoDeposito(event.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updatePago} id="botonModal">
              Agregar Pago
          </Button>
          <Button variant="secondary" onClick={handleClose}>
              Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/**modal activar desactivar usuario */}
      <Modal  show={showModal} onHide={cerrarModal} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title id="tituloModal">{tituloModal} Contrato</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <>
            {
              (alert == true) ?
                <Container>
                  <Alert variant="warning" onClose={() => setAlert(false)} dismissible>
                    <Alert.Heading>Advertencia</Alert.Heading>
                    <p>
                      {msjError}
                    </p>
                  </Alert>
              </Container>
              :(<></>)
            }
          </>
          <Form.Group>
            <Form.Control placeholder="nombre"   
              id="idUsuarioActivar"
              name="idUsuario"
              type="hidden"
              defaultValue={idContrato}
            />
          </Form.Group>
          <h4>{mensajemodal}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={activarDesactivarContrato} id="botonModal">
              {botonModal}
          </Button>
          <Button variant="secondary" onClick={cerrarModal}>
              Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
        {/**fin modals */}
    </> 
    );
}

export default Contratos;
                        
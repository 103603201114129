import { API_URL } from "../utils/Constant";
import { getTokenApi } from "./Token";

export async function saveMuebleApi(formdata){
    try {
        const url= `${API_URL}Articulos/saveMueble`;
        const params = {
            method: 'POST',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
            body: formdata
        };
 
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getAllArticulosApi(formdata){
    try {
        const url= `${API_URL}Articulos/getAllArticulos`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getAllCategoriasArticulosApi(formdata){
    try {
        const url= `${API_URL}Articulos/getAllCategoriasArticulos`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function activarDesactvarArticuloApi(formdata){
    try {
        const url= `${API_URL}Articulos/activarDesactvarArticulo`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function findByCatIdApi(formdata){
    try {
        const url= `${API_URL}Articulos/findByCatId`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
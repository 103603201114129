import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup,Alert, FormLabel} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import Select from 'react-select';
import "../usuarios/Usuario.css";
import {
    useNavigate,useParams
  } from "react-router-dom";
import { getDatosUsers } from '../../api/Login';
import { replace } from 'formik';
import { getTokenApi } from '../../api/Token';
import { getPagosByIdUsuarioApi } from '../../api/Usuarios';
function Recaudacion(){
    const [auth, setAuth] = useState(null);

    let params = useParams();
    let navigate = useNavigate();
    let timer = 3000;
    let delay = 3000;
    let prevent = false;
    const [fechaini,setFechaini] = useState("");
    const [fechafin,setFechafin] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [idrecaudacion,setIdRecaudacion] = useState(0);
    const [recaudacion,setRecaudacion] = useState(null);
    const [totalRecabado,setTotalRecabado] =useState(0);
    const getAllRecaudacion = useCallback( async(fechaini1,fechafin1,rol,id) => {
        var userID = 0;
        if(rol != 1){
            userID = id;
        }
        const TOKEN = await getTokenApi();
        const formdata={
            "token" : TOKEN,
            "idUsuario" : (params.recaudacionId != null) ? params.recaudacionId:userID,
            "fechaini" :fechaini1,
            "fechafin" :fechafin1,
            "rol" :rol,

        }
        const pagos = await getPagosByIdUsuarioApi(formdata);
        setRecaudacion(pagos.recaudacion);
        if (pagos.totalRecabado != null) {
            setTotalRecabado(pagos.totalRecabado);
        }
       
        //console.log(pagos);
        //setContratoDatos(pagos.contrato);
        //setPagosArray(pagos.pagos);
        setIsLoading(false);
      },[]);
    useEffect(
        () => {
            const init = async()=>{
                const user = await getDatosUsers();
                console.log(user);
                
                if (user === null) {
                    navigate("/",{replace:true});
                    setAuth(null)
                }else{
                    /**llamar al api para obtener todos los pagos */
                    if (params.recaudacionId != null) {
                        setIdRecaudacion(params.recaudacionId);
                        getAllRecaudacion('','',user.rol);
                        
                    } else {
                       getAllRecaudacion('','',user.rol,user.id);
                    }
                    setAuth(user);
                }
            }
            init();
        },[]
    );
    var busquedaFuncion = () =>{
        setIsLoading(true);
        //console.log("fechas",fechafin,fechaini);
        getAllRecaudacion(fechaini,fechafin,auth.rol,auth.id);
      }
    
    return(
        <>
        <br></br>
        <div className="contenido">
            <div className="sub_contenido">
                <div className='filtros_pag'>
                    
                    <Form.Group>
                        <InputGroup>
                        <Form.Control 
                                type="date" 
                                name="horaEntrada"
                                defaultValue={fechaini} 
                                placeholder="Entrada"
                                onChange={event => setFechaini(event.target.value)}
                        />&nbsp;   
                        <Form.Control 
                                type="date" 
                                name="horaSalida" 
                                defaultValue={fechafin} 
                                placeholder="Salida"
                                onChange={event => setFechafin(event.target.value)}
                        />&nbsp;
                        </InputGroup>
                  </Form.Group>
                    <Form.Group>
                        <Button title="ExportarPDF" onClick={busquedaFuncion} className="botonIcono botonNuevo showmessages">Buscar</Button>
                        &nbsp;&nbsp;
                    </Form.Group>
                </div>
                {
                    isLoading ? (
                        <LoadingSpinner showSpinner={isLoading}/>
                    ):(<></>)
                } 
                <Table>
                    <Thead>
                    <Tr>
                        <Th className="borderTabla">Nombre de Usuario</Th>
                        {
                            auth ? (
                            (params.recaudacionId != null || auth.rol != 1) && (
                                <>
                                 <Th className="borderTabla">Fecha de Cobro</Th>
                                 <Th className="borderTabla">Folio de Contrato</Th>
                                </>
                            )
                            ):(<></>)
                        }
                        
                       
                        <Th className="borderTabla">Cantidad Recabada</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                        {
                            recaudacion != null && (
                                map(recaudacion,(r,index)=>(
                                <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                    <Td className="borderTabla">{r.nombreCompleto}</Td> 
                                    {
                                        auth ? (
                                            (params.recaudacionId != null || auth.rol != 1) && (
                                            <>
                                                <Td className="borderTabla">{r.Fecha_Pago}</Td>
                                                <Td className="borderTabla">{r.Id_Venta}</Td>
                                            </>     
                                        )
                                        ):(<></>)
                                    }                              
                                    <Td className="borderTabla">${r.Cantidad_Pago}</Td>
                                </Tr>
                                ))
                            )
                        }
                        {
                            totalRecabado != 0 && (
                                <>
                                 <Tr className="fondoTabla">
                                 {
                                     auth ? (
                                        (params.recaudacionId != null || auth.rol != 1) && (
                                            <>
                                                <Td className="borderTabla"></Td>
                                                <Td className="borderTabla"></Td>
                                            </>     
                                        )
                                    ):(<></>)
                                    }          
                                    
                                    <Td className="borderTabla"><b>Total Recabado</b></Td>
                                    <Td className="borderTabla"><b>${totalRecabado}</b></Td>
                                 </Tr>
                                </>
                            )
                        }
                    </Tbody>
                </Table>
            </div>
        </div>
        </>
    );
}
export default Recaudacion;
import React, {useContext,useState,useEffect,useCallback} from 'react';
import { Button, Table ,Modal, Container, Row, Col, Form} from "react-bootstrap";


function Pagare(props){
    const {precio,colPobCliente,nombreCliente,direccionCliente,celCliente,fecha} = props;
    return(
        <>
         <Row className='cuadroPagare'>
                <Row>
                    <Col md={6}>
                    <b>PAGARÉ</b>
                    </Col>
                
                    <Col md={6}>
                    <b>BUENO POR ${precio}</b>
                    </Col>
                </Row>
                <Row>
                    <Col className='textAlignEnd' md={12}>
                        <b>En {colPobCliente} A {fecha} </b>
                    </Col>
                </Row>
                <Row>
                    <Col className='textoInicioPagare' md={12}>
                    Debo(mos) y pagaré(mos) incondicionalmente por este Pagaré a la orden de <b>Muebles San Nicolás</b>
                    </Col>
                    <Col className='textoInicioPagare' md={12}>
                        La cantidad de: <b>${precio} pesos mexicanos</b>
                    </Col>
                    <Col className='textoInicioPagare' md={12}>
                        Valor recibido a mi (nuestra) entera satisfacción. Este pagaré forma parte de una serie numerada
                        y todos estan sujetos a la condicion de que, al no pagarse cualquiera de ellos a su vencimiento seran
                        exigibles todos los que le sigan en numero, además de los ya vencidos, desde la fecha de este documento
                        hasta el día de su liquidación causara intereses monetarios.
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col md={6}>
                        <p><b>Datos del Deudor</b></p>
                        <p className='textoInicioPagare'>Nombre: {nombreCliente}</p>
                        <p className='textoInicioPagare'>Dirección: {direccionCliente}</p>
                        <p className='textoInicioPagare'>Colonia/Poblacion: {colPobCliente}</p>
                        <p className='textoInicioPagare'>Telefono: {celCliente}</p>
                    </Col>
                </Row>
            </Row>
        </>
    );
}
export default Pagare;
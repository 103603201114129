import { API_URL } from "../utils/Constant";
import { getTokenApi } from "./Token";

export async function saveContratoApi(formdata){
    try {
        const url= `${API_URL}Contrato/saveContrato`;
        const params = {
            method: 'POST',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
            body: formdata
        };
 
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getAllContratosApi(formdata){
    try {
        const url= `${API_URL}Contrato/getAllContratos`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getEstatusContratoPagoApi(formdata) {
    try {
        const url= `${API_URL}Contrato/getEstatusContratoPago`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function agregarPagoApi(formdata) {
    try {
        const url= `${API_URL}Contrato/agregarPago`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getPagosByIdVentaApi(formdata) {
    try {
        const url= `${API_URL}Contrato/getPagosByIdVenta`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getContratoByIdApi(formdata) {
    try {
        const url= `${API_URL}Contrato/getContratoById`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function cancelarContratoByIdApi(formdata) {
    try {
        const url= `${API_URL}Contrato/cancelarContratoById`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
import React from 'react';
import './App.css';
import Rutas from "./router/Rutas";
import Footer from "./componentes/cuerpo/Footer";
function App() {
  return (
    <div className="App">
      <div className='contenedor'>
        {/*<Header/>*/}

          <Rutas/>
        <Footer/>
      </div>
    </div>

  );
}

export default App;
